import { Injectable } from '@angular/core';
import { environment } from '@env';
import * as mixpanel from 'mixpanel-browser';
import { MixpanelEvent } from '../models/mixpanel-event.model';

@Injectable({
  providedIn: 'root',
})
export class MixpanelService {
  constructor() {}

  init(): void {
    mixpanel.init(environment.mixpanel, {
      debug: true,
      track_pageview: true,
      persistence: 'localStorage',
    });
  }

  identify(userToken: string): void {
    mixpanel.identify(userToken);
  }

  track(event: MixpanelEvent, properties?: Record<string, any>): void {
    mixpanel.track(event, properties || {});
  }
}
