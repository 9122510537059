<div class="modal-header">
  <h5 class="modal-title" id="addContactModalLabel">{{ settings.title }}</h5>
  <!-- <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="close()"></button> -->
</div>
<div class="modal-body">
  <p [innerHTML]="settings.innerContent"></p>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-light w-sm" (click)="cancelClick()">{{ settings.cancelText }}</button>
  <button
    class="btn btn-{{ settings.isDanger ? 'danger' : 'primary' }} w-sm"
    [disabled]="isSubmitted"
    (click)="confirmClick()"
    id="btn-save-event">
    {{ settings.confirmText }}
    <span class="spinner-border spinner-border-sm ms-2" role="status" *ngIf="isSubmitted" aria-hidden="true"> </span>
  </button>
</div>
