import { NgbDate } from '@ng-bootstrap/ng-bootstrap';

type ReportDateFormat = `${number}-${number}-${number}`;

export function CONVERT_DATE_TO_STRING(date: NgbDate): ReportDateFormat {
  if (!date) {
    return null;
  }
  return `${date.year}-${date.month}-${date.day}`;
}
