import { Directive, Input, OnInit, TemplateRef, ViewContainerRef } from '@angular/core';
import { environment } from '@env';
import { RequiredFeature } from '../consts/user-role.enum';

@Directive({
  selector: '[appRequiredFeature]'
})
export class RequiredFeatureDirective implements OnInit {

  @Input() appRequiredFeature: any;

  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef
  ) { }

  ngOnInit(): void {
    const features = this.appRequiredFeature || [];
    if (environment.enableDataspaces && features?.includes( RequiredFeature.Dataspaces) 
    || environment.enableUsageReports && features?.includes(RequiredFeature.UsageReports)
    || environment.enableFeedbackReports && features?.includes(RequiredFeature.FeedbackReports)) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    } else if (features && features?.find((feature: any) => feature)) {
      this.viewContainer.clear();
    } else {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
  }
}
