import { AISystemNameResponse } from '@core/models/response';

export class CreateOpenAiChatRequest {
  systemMessage: string;
  model: string;
  personaId: string;
  configuration: Record<string, any>;
  replacements: Record<string, any>;
  metadata: Record<string, any>;
  aiSystem?: AISystemNameResponse;
  public constructor(init?: Partial<CreateOpenAiChatRequest>) {
    Object.assign(this, init);
  }
}

export class CreateOpenAiPersonaChatRequest {
  personaId: string;
  replacements: Record<string, any>;
  metadata: Record<string, any>;
  public constructor(init?: Partial<CreateOpenAiPersonaChatRequest>) {
    Object.assign(this, init);
  }
}
export class UpdateOpenAiChatRequest extends CreateOpenAiChatRequest {
  conversationId: string;
  message: string;
  public constructor(init?: Partial<UpdateOpenAiChatRequest>) {
    super();
    Object.assign(this, init);
  }
}
export class CreatePersonaChatRequest {
  personaId!: string;
  promptText!: string;
  public constructor(init?: Partial<CreatePersonaChatRequest>) {
    Object.assign(this, init);
  }
}
