import { Injectable, Pipe, PipeTransform } from '@angular/core';
import { BrowserService } from '../browser.service';
import { AuthenticationService } from '@core-services/authentication.service';

@Pipe({
  name: 'currencyFormat',
})
@Injectable()
export class CurrencyFormatPipe implements PipeTransform {
  constructor(public authenticationService: AuthenticationService, public browserService: BrowserService) {}

  // tslint:disable-next-line: variable-name
  transform(number: number, currencyCode: string = 'INR'): any {
    let localCurrencyCode = currencyCode;
    if (!localCurrencyCode) {
      localCurrencyCode = currencyCode;
    }
    if (!localCurrencyCode) {
      localCurrencyCode = 'INR';
    }
    const formatter = new Intl.NumberFormat(this.getLang(), {
      style: 'currency',
      currency: localCurrencyCode,
    });

    return formatter.format(number);
  }

  getLang() {
    if (this.browserService.getWindow().navigator.languages !== undefined) {
      return this.browserService.getWindow().navigator.languages[0];
    } else {
      return this.browserService.getWindow().navigator.language;
    }
  }
}
