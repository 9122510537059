import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getTimeDifference',
})
export class GetTimeDifferencePipe implements PipeTransform {
  transform(startTime: Date, endTime: Date): string {
    if (!startTime || !endTime) {
      return '-';
    }
    const diff = endTime.getTime() - startTime.getTime();
    const minutes = Math.floor(diff / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return `${minutes}m${seconds}s`;
  }
}
