import { ActionReducerMap } from '@ngrx/store';
import { appConfigsReducer, AppConfigsState } from '@app/store/app-configs/app-configs.reducer';
import { AppConfigsEffects } from '@app/store/app-configs/app-configs.effects';
export interface AppState {
  appConfigs: AppConfigsState;
}

export const reducers: ActionReducerMap<AppState> = {
  appConfigs: appConfigsReducer,
};

export const effects: any[] = [AppConfigsEffects];
