import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { AuthenticationService } from '@core-services/authentication.service';
import { ToastService, ToastType } from '@shared/services/toast.service';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { TranslateService } from '@ngx-translate/core';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
  refreshTokenUrl = 'api/auth/refresh-credentials';
  constructor(
    private authenticationService: AuthenticationService,
    private toastService: ToastService,
    private modalService: NgbModal,
    private readonly injector: Injector
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(
      catchError(err => {
        if (request?.url.includes(this.refreshTokenUrl)) {
          this.authenticationService.logout();
          return throwError(() => error);
        }

        if ([400, 401, 403].includes(err.status) && this.authenticationService.userValue) {
          // close all modals
          this.modalService.dismissAll();
          // auto logout if 400, 401 or 403 response returned from api
          this.authenticationService.logout();
          return throwError(() => error);
        }

        const error = (err && err.error && err.error.message) || err.statusText;
        console.error(err);

        if (err.status === 500 || err.error?.statusCode === 555) {
          const messageKey = err.error?.message;
          const translateService = this.injector.get(TranslateService);
          const message = translateService.instant(messageKey);
          message && message !== messageKey ? this.toastService.show(message, ToastType.Danger) : this.toastService.showDefaultError();
        } else {
          this.toastService.showDefaultError();
        }
        return throwError(() => error);
      })
    );
  }
}
