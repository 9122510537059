import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestBaseService } from '@core/rest-base.service';
import { ImageResponse } from '@core/models/response';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { DomSanitizer } from '@angular/platform-browser';

@Injectable({
  providedIn: 'root',
})
export class ImagesService extends RestBaseService {
  constructor(http: HttpClient, private sanitizer: DomSanitizer) {
    super(http);
  }

  post(formData: FormData): Observable<ImageResponse> {
    return this.http.post<ImageResponse>(`${this.apiUrl}/api/images`, formData);
  }

  get(uploadPath: string) {
    return this.http
      .get(`${this.apiUrl}/api/images/${uploadPath}`, { responseType: 'blob' })
      .pipe(map(val => this.sanitizer.bypassSecurityTrustUrl(URL.createObjectURL(val))));
  }
}
