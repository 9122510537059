import { Pipe, PipeTransform } from '@angular/core';
import { WorkspaceResponse, WorkspaceRole } from '@app/core/models/response';
import { WorkspaceAction } from '@app/core/models/workspace-action.enum';
import { ActionToRolesMap } from '../consts/action-to-roles.const';
import { AuthenticationService } from '@app/core/services';
import { UserRoleEnum } from '../consts/user-role.enum';

@Pipe({
  name: 'isValidWorkspaceRole',
})
export class IsValidWorkspaceRolePipe implements PipeTransform {
  constructor(private authService: AuthenticationService) {}

  transform(actionName: WorkspaceAction, workspace: WorkspaceResponse): boolean {
    const roles = ActionToRolesMap[actionName];
    if (!roles || !workspace) {
      return true;
    }
    const currentUser = this.authService.userSubject.getValue();
    if (currentUser.organizationRoleId == UserRoleEnum.Owner || currentUser.organizationRoleId == UserRoleEnum.Admin) return true;
    const workspaceRoleId = workspace?.users?.find((u: any) => u.userId === currentUser.userId)?.workspaceRoleId;
    if (!roles.find((r: any) => r === workspaceRoleId)) {
      return false;
    } else {
      return true;
    }
  }
}
