import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-markdown-code',
  templateUrl: './markdown-code.component.html',
  styleUrls: ['./markdown-code.component.scss'],
})
export class MarkdownCodeComponent {
  @Input() content = '';
  @Input() codeLang: string = '.json';
}
