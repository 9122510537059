import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-markdown-prerendered',
  templateUrl: './markdown-prerendered.component.html',
  styleUrls: ['./markdown-prerendered.component.scss'],
})
export class MarkdownPrerenderedComponent {
  @Input() textContent: string;
}
