<!-- Right Sidebar -->
<div class="right-bar" (clickOutside)="hide()" [exclude]="'.right-bar-toggle'">
  <div data-simplebar class="h-100">
    <div class="rightbar-title d-flex align-items-center bg-dark p-3">
      <h5 class="m-0 me-2 text-white">{{ 'app_rightsidebar.theme-customizer.text' | translate }}</h5>

      <a href="javascript:void(0);" class="right-bar-toggle-close right-bar-toggle ms-auto" (click)="hide()">
        <i class="mdi mdi-close noti-icon"></i>
      </a>
    </div>

    <!-- Settings -->
    <hr class="m-0" />

    <div class="p-4">
      <h6 class="mb-3">{{ 'app_rightsidebar.layout.text' | translate }}</h6>
      <div class="form-check form-switch">
        <input class="form-check-input" type="checkbox" id="is-layout" (click)="changeLayout($event)" />
        <label class="form-check-label" for="is-layout">{{
          'app_rightsidebar.layout.list.' + (layout === 'vertical' ? 'vertical' : 'horizontal') | translate
        }}</label>
      </div>

      <h6 class="mt-4 mb-3">{{ 'app_rightsidebar.layout_mode.text' | translate }}</h6>

      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="mode"
          id="layout-mode-light"
          value="light"
          [checked]="mode === 'light'"
          (change)="changeMode('light')" />
        <label class="form-check-label" for="layout-mode-light">{{ 'app_rightsidebar.layout_mode.list.light' | translate }}</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="mode"
          id="layout-mode-dark"
          value="dark"
          [checked]="mode === 'dark'"
          (change)="changeMode('dark')" />
        <label class="form-check-label" for="layout-mode-dark">{{ 'app_rightsidebar.layout_mode.list.dark' | translate }}</label>
      </div>

      <h6 class="mt-4 mb-3">{{ 'app_rightsidebar.layout_width.text' | translate }}</h6>

      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="layout-width"
          id="layout-width-fuild"
          value="fluid"
          [checked]="width === 'fluid'"
          (change)="changeWidth('fluid')" />
        <label class="form-check-label" for="layout-width-fuild">{{ 'app_rightsidebar.layout_width.list.fluid' | translate }}</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="layout-width"
          id="layout-width-boxed"
          value="boxed"
          [checked]="width === 'boxed'"
          (change)="changeWidth('boxed')" />
        <label class="form-check-label" for="layout-width-boxed">{{ 'app_rightsidebar.layout_width.list.boxed' | translate }}</label>
      </div>

      <h6 class="mt-4 mb-3">{{ 'app_rightsidebar.layout_position.text' | translate }}</h6>

      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="layout-position"
          id="layout-position-fixed"
          value="fixed"
          [checked]="position === 'fixed'"
          (change)="changePosition('fixed')" />
        <label class="form-check-label" for="layout-position-fixed">{{ 'app_rightsidebar.layout_position.list.fixed' | translate }}</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="layout-position"
          id="layout-position-scrollable"
          value="scrollable"
          [checked]="position === 'scrollable'"
          (change)="changePosition('scrollable')" />
        <label class="form-check-label" for="layout-position-scrollable">{{
          'app_rightsidebar.layout_position.list.scrollable' | translate
        }}</label>
      </div>

      <h6 class="mt-4 mb-3">{{ 'app_rightsidebar.topbar_color.text' | translate }}</h6>

      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="topbar-color"
          id="topbar-color-light"
          value="light"
          [checked]="topbar === 'light'"
          (change)="changeTopbar('light')" />
        <label class="form-check-label" for="topbar-color-light">{{ 'app_rightsidebar.topbar_color.list.light' | translate }}</label>
      </div>
      <div class="form-check form-check-inline">
        <input
          class="form-check-input"
          type="radio"
          name="topbar-color"
          id="topbar-color-dark"
          value="dark"
          [checked]="topbar === 'dark'"
          (change)="changeTopbar('dark')" />
        <label class="form-check-label" for="topbar-color-dark">{{ 'app_rightsidebar.topbar_color.list.dark' | translate }}</label>
      </div>
      <div *ngIf="layout !== 'horizontal'">
        <div id="sidebar-setting">
          <h6 class="mt-4 mb-3 sidebar-setting">{{ 'app_rightsidebar.sidebar_size.text' | translate }}</h6>

          <div class="form-check sidebar-setting">
            <input
              class="form-check-input"
              type="radio"
              name="sidebar-size"
              id="sidebar-size-default"
              value="default"
              [checked]="sidebarsize === 'default'"
              (change)="changeSidebarSize('default')" />
            <label class="form-check-label" for="sidebar-size-default">{{
              'app_rightsidebar.sidebar_size.list.default' | translate
            }}</label>
          </div>
          <div class="form-check sidebar-setting">
            <input
              class="form-check-input"
              type="radio"
              name="sidebar-size"
              id="sidebar-size-compact"
              value="compact"
              [checked]="sidebarsize === 'compact'"
              (change)="changeSidebarSize('compact')" />
            <label class="form-check-label" for="sidebar-size-compact">{{
              'app_rightsidebar.sidebar_size.list.compact' | translate
            }}</label>
          </div>
          <div class="form-check sidebar-setting">
            <input
              class="form-check-input"
              type="radio"
              name="sidebar-size"
              id="sidebar-size-small"
              value="small"
              [checked]="sidebarsize === 'small'"
              (change)="changeSidebarSize('small')" />
            <label class="form-check-label" for="sidebar-size-small">{{ 'app_rightsidebar.sidebar_size.list.small' | translate }}</label>
          </div>

          <h6 class="mt-4 mb-3 sidebar-setting">{{ 'app_rightsidebar.sidebar_color.text' | translate }}</h6>

          <div class="form-check sidebar-setting">
            <input
              class="form-check-input"
              type="radio"
              name="sidebar-color"
              id="sidebar-color-light"
              value="light"
              [checked]="sidebarcolor === 'light'"
              onchange="document.body.setAttribute('data-sidebar', 'light')" />
            <label class="form-check-label" for="sidebar-color-light">{{ 'app_rightsidebar.sidebar_color.list.light' | translate }}</label>
          </div>
          <div class="form-check sidebar-setting">
            <input
              class="form-check-input"
              type="radio"
              name="sidebar-color"
              id="sidebar-color-dark"
              value="dark"
              [checked]="sidebarcolor === 'dark'"
              onchange="document.body.setAttribute('data-sidebar', 'dark')" />
            <label class="form-check-label" for="sidebar-color-dark">{{ 'app_rightsidebar.sidebar_color.list.dark' | translate }}</label>
          </div>
          <div class="form-check sidebar-setting">
            <input
              class="form-check-input"
              type="radio"
              name="sidebar-color"
              id="sidebar-color-brand"
              value="brand"
              [checked]="sidebarcolor === 'brand'"
              onchange="document.body.setAttribute('data-sidebar', 'brand')" />
            <label class="form-check-label" for="sidebar-color-brand">{{ 'app_rightsidebar.sidebar_color.list.brand' | translate }}</label>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- end slimscroll-menu-->
</div>
<!-- /Right-bar -->
