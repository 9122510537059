import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageClientService {
  constructor(private http: HttpClient) {}

  private getEntities<T>(entityName: string) {
    try {
      return JSON.parse(localStorage.getItem(entityName)) as T[];
    } catch (err) {
      return [] as T[];
    }
  }

  private setEntities<T>(entityName: string, entities: T[]) {
    localStorage.setItem(entityName, JSON.stringify(entities));
  }

  initializeList<T>(entityName: string, initialValues: T[]) {
    this.setEntities(entityName, initialValues);
  }

  getList<T>(entityName: string) {
    const entities = this.getEntities(entityName);
    return of(entities as T[]);
  }

  getEntity<T>(entityName: string, entityId: string) {
    return of(this.getEntities(entityName).find((x: any) => x.id === entityId));
  }

  addEntity<T>(entityName: string, entity: T) {
    (entity as any).id = (Math.random() + 1).toString(36).substring(7);
    let list = this.getEntities(entityName);
    if (!list) {
      // fallback for case when list not exist yet
      list = [];
    }
    list.push(entity);
    this.setEntities(entityName, list);
    return this.getList<T>(entityName);
  }

  updateEntity<T>(entityName: string, entity: T) {
    const list = this.getEntities(entityName);

    list[list.indexOf(list.find((x: any) => x.id === (entity as any).id))] = entity;
    this.setEntities(entityName, list);
    return this.getList<T>(entityName);
  }

  deleteEntity<T>(entityName: string, entity: T) {
    const list = this.getEntities(entityName);
    list.splice(list.indexOf(entity), 1);
    this.setEntities(entityName, list);
    return this.getList<T>(entityName);
  }

  setSingleEntity<T>(entityName: string, value: T) {
    localStorage.setItem(entityName, JSON.stringify(value));
  }

  getSingleEntity<T>(entityName: string) {
    return JSON.parse(localStorage.getItem(entityName)) as T;
  }
}
