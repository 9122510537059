export const environment = {
  production: false,
  //apiUrl: 'https://api.hyperleap.ai',
  //apiUrl: 'https://hyperleap-dev-api.azurewebsites.net',
  apiUrl: 'https://api-dev1.hyperleap.ai',
  //apiUrl: 'https://localhost:44360',
  stripe: {
    publishableKey: 'pk_test_thTQf0YMbJ0JjuS261QQxThc00wKpMj4tA',
    pricingTableId: 'prctbl_1NnhRPJB1MdAdrWFqa6P7sSc',
  },
  mixpanel: 'ce601c811585475a299b6fdf9ed5dffd',
  recaptcha: {
    siteKey: '6LcW9QMoAAAAAAfqg41vkpbKh5ZX2sesvMFtR5AA',
  },
  intercom: {
    appId: 'x2e7masw',
  },
  enableDataspaces: true,
  enableUsageReports: true,
  enableFeedbackReports: true,
  postHog: {
    key: 'phc_6DgGDFYHUdUOOr79cRrG2LHn9B8IAehhBIGPphPrsgI',
    host:'https://app.posthog.com'
  }
};
