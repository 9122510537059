import { Component, EventEmitter, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-markdown-result',
  templateUrl: './markdown-result.component.html',
  styleUrls: ['./markdown-result.component.scss'],
})
export class MarkdownResultComponent implements OnInit {
  textContent = '';

  @Input() onNewDataReceived: EventEmitter<string>;
  @Input() onNewRequestReceived: EventEmitter<string>;
  @Input() onNewFullDataReceived: EventEmitter<string>;
  constructor() {}

  ngOnInit(): void {
    if (this.onNewDataReceived) {
      this.onNewDataReceived.subscribe(chunk => {
        this.textContent += chunk;
      });
    }
    if (this.onNewRequestReceived) {
      this.onNewRequestReceived.subscribe(request => {
        if (request == 'clear') this.textContent = '';
      });
    }
    if (this.onNewFullDataReceived) {
      this.onNewFullDataReceived.subscribe(data => {
        this.textContent = data;
      });
    }
  }
}
