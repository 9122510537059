<div class="form-floating mb-3 tag-container">
  <label for="promptTags" class="form-label" [ngClass]="{ minimize: isTagInputFocused }"> Prompt tags </label>
  <tag-input
    class=""
    id="promptTags"
    [(ngModel)]="chips"
    (ngModelChange)="valueChange()"
    [removable]="true"
    [placeholder]="'app_actions.list.write_tag_click_enter' | translate"
    [clearOnBlur]="true"
    [inputText]="tagInputText"
    [ripple]="false"
    [editable]="true"
    (onFocus)="onTagInputFocus()"
    (onBlur)="onTagInputBlur()">
  </tag-input>
</div>
