import { SessionService } from './core/services/session.service';
import { Subject } from 'rxjs';
import { filter, map, takeUntil } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { BroadcasterService } from '@core/services/broadcaster.service';
import { getAppConfigs } from '@app/store/app-configs/app-configs.actions';
import { AppConfigsState } from '@app/store/app-configs/app-configs.reducer';
import { Store } from '@ngrx/store';
import { MixpanelService } from './core/services/mixpanel.service';
import { StripeService } from './core/services/stripe.service';
import { LayoutMode } from './core/models/layout-mode.enum';
import { ThemeService } from './core/services/theme.service';
import { SwUpdate } from '@angular/service-worker';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnDestroy, OnInit {
  $destroy: Subject<void> = new Subject();

  constructor(
    private _broadcatser: BroadcasterService,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private translate: TranslateService,
    private sessionService: SessionService,
    private store: Store<AppConfigsState>,
    private mixpanelService: MixpanelService,
    private stripeService: StripeService,
    private themeService: ThemeService,
    private updates: SwUpdate
  ) {
    // app component broadasting
    this._broadcatser.broadcast('mykey', 'myvalue');
    //set dummy token just to enable auth guard for after-login module
    localStorage.setItem('token', 'dummy');

    /**
     * do this in other page, for e.g I'm doing here only
     * use this service with takeUntil from rxJS and local Subject to prevent memory leaks like shown
     */
    this._broadcatser
      .listen('mykey')
      .pipe(takeUntil(this.$destroy))
      .subscribe({
        next: data => console.log(data), // your broadcasted value
      });

    this.initializePageTitleListener();
    this.initializeTranslations();

    this.mixpanelService.init();
    this.stripeService.init();
    this.updates.versionUpdates.subscribe(event => {
      if (confirm('A new version of the app is available. Load the new version?')) {
        this.updates.activateUpdate().then(() => document.location.reload());
      }
    });
  }

  ngOnInit() {
    this.store.dispatch(getAppConfigs());
    this.sessionService.generateSessionId();
    this.switchThemeForAccountPages();
  }

  initializeTranslations() {
    this.translate.addLangs(['en']);
    this.translate.setDefaultLang('en');
    const browserLang = this.translate.getBrowserLang();
    //translate.use(browserLang.match(/en|fr/) ? browserLang : 'en');
    this.translate.use(browserLang.match(/en/) ? browserLang : 'en');
  }

  initializePageTitleListener() {
    this.router.events
      .pipe(
        filter(event => event instanceof NavigationEnd),
        map(() => {
          let child = this.activatedRoute.firstChild;
          while (child) {
            if (child.firstChild) {
              child = child.firstChild;
            } else if (child.snapshot.data && child.snapshot.data.pageTitle) {
              return child.snapshot.data.pageTitle;
            } else {
              return null;
            }
          }
          return null;
        })
      )
      .subscribe((watchedValue: string) => {
        //generated by AI
        if (watchedValue) {
          this.translate.get(watchedValue).subscribe(title => {
            document.title = title;
          });
        }
      });
  }

  private switchThemeForAccountPages() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        const newUrl = event.url;
        if (newUrl.includes('/account')) {
          this.themeService.changeTheme(LayoutMode.Light);
        }
      }
    });
  }

  ngOnDestroy() {
    this.$destroy.next();
    this.$destroy.complete();
  }
}
