import { Component, EventEmitter, Input, Output } from '@angular/core';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';

export const DEFAULT_CONFIRMATION_MODAL_SETTINGS = {
  title: 'Are you sure?',
  innerContent: 'Are you sure?',
  confirmText: 'Confirm',
  cancelText: 'Cancel',
  isDanger: false,
};

@Component({
  selector: 'app-confirmation-modal',
  templateUrl: './confirmation-modal.component.html',
  styleUrls: ['./confirmation-modal.component.scss'],
})
export class ConfirmationModalComponent {
  @Input() settings: {
    title?: string;
    innerContent: string;
    confirmText?: string;
    cancelText?: string;
    isDanger: boolean;
  } = DEFAULT_CONFIRMATION_MODAL_SETTINGS;
  @Output() onConfirmClick = new EventEmitter();
  @Output() onCancelClick = new EventEmitter();

  isSubmitted = false;

  constructor(public activeModal: NgbActiveModal) {}

  confirmClick() {
    this.isSubmitted = true;
    this.onConfirmClick.emit();
    this.close();
  }

  cancelClick() {
    this.onCancelClick.emit();
    this.close();
  }

  close() {
    this.activeModal.close();
  }
}
