export class CreatePromptRequest {
  promptName?: string;
  promptDescription?: string;
  promptTags?: string;
  aiSystem?: string;
  model?: string;
  isActive?: boolean;
  isSecret?: boolean;
  isApiEnabled?: boolean;
  promptText?: string;
  metadata?: Record<string, any>;
  configuration?: Record<string, any>;
  defaultPromptId?: string;
  public constructor(init?: Partial<CreatePromptRequest>) {
    Object.assign(this, init);
  }
}
export class UpdatePromptRequest extends CreatePromptRequest {
  promptId: string;
  public constructor(init?: Partial<UpdatePromptRequest>) {
    super();
    Object.assign(this, init);
  }
}
export class CreatePromptVersionRequest {
  promptId: string;
  promptName: string;
  promptText: string;
  isActive: boolean;
  metadata: Record<string, any>;
  configuration: Record<string, any>;
  public constructor(init?: Partial<CreatePromptVersionRequest>) {
    Object.assign(this, init);
  }
}
export class UpdatePromptVersionRequest extends CreatePromptVersionRequest {
  promptVersionId: string;
  public constructor(init?: Partial<UpdatePromptVersionRequest>) {
    super();
    Object.assign(this, init);
  }
}
// export class PromptResponse {
//     promptId: string;
//     promptName: string;
//     promptDescription: string;
//     aiSystem: string;
//     model: string;
//     isActive: boolean;
//     isSecret: boolean;
//     isApiEnabled: boolean;
//     promptText: string;
//     defaultVersionId: string;
//     metadata: Record<string, any>;
//     configuration: Record<string, any>;
//     promptVersions: PromptVersionResponse[];
//     public constructor(init?: Partial<PromptResponse>) {
//         Object.assign(this, init);
//     }
// }

export enum PatchPromptAction {
  SetDefaultVersion = 'set-default-version',
  SetNameAndDescription = 'set-name-and-description',
  SetSecret = 'set-secret',
  SetActive = 'set-active',
  SetApiEnabled = 'set-api-enabled',
}

export enum PatchPromptVersionAction {
  SetPrompt = 'set-prompt',
}
