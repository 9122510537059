import { User, UserResponse } from '../auth';
import { PersonaResponse } from './avatar';
import { PromptResponse } from './prompts';
import { AttachPromptToDataspaceRequest, CreateDataspaceUser } from '@core/models/request';

export class DataspaceResponse {
  dataspaceId: string;
  dataspaceName: string;
  dataspaceDescription: string;
  prompts: DataspacePromptResponse[];
  personas: DataspacePersonaResponse[];
  users: DataspaceUserResponse[];
  promptCount: number;
  personaCount: number;
  userCount: number;
  isActive: boolean;
  documentCount?: number;
  imageCount?: number;
  stringCount?: number;
  websiteCount?: number;
  dataspaceDocuments: any[];
  dataspaceImages: any[];
  dataspaceStrings:any[];
  dataspaceWebsites:any[];
}
export class DataspacePromptResponse {
  dataspacePromptId: string;
  dataspaceId: string;
  promptId: string;
  prompt: PromptResponse;
  isChattable: boolean;
}
export class DataspacePersonaResponse {
  dataspacePersonaId: string;
  dataspaceId: string;
  personaId: string;
  persona: PersonaResponse;
  isChattable: boolean;
}
export class DataspaceUserResponse {
  dataspaceUserId: string;
  dataspaceId: string;
  userId: string;
  dataspaceRoleId: DataspaceRole;
  user: UserResponse;
}

export class CreateDataspaceUserResponse extends CreateDataspaceUser {
  user: User;
}

export class AttachPromptToDataspaceResponse extends AttachPromptToDataspaceRequest {
  prompt: PromptResponse;
}

export class DataspaceCounts {
  activeDataspaceCount: number;
  archivedDataspaceCount: number;
}

export enum DataspaceRole {
  None = 0,
  Owner = 1,
  Admin = 2,
  Engineer = 3,
  Reader = 4,
  Guest = 5,
}

export const DataspaceRoleNameMap = {
  [DataspaceRole.None]: 'None',
  [DataspaceRole.Owner]: 'Owner',
  [DataspaceRole.Admin]: 'Admin',
  [DataspaceRole.Engineer]: 'Engineer',
  [DataspaceRole.Reader]: 'Reader',
  [DataspaceRole.Guest]: 'Guest',
};
