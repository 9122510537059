<div class="p-3 border" [class]="classToAttach">
  <app-markdown-result
    [onNewDataReceived]="onDataReceived"
    [onNewRequestReceived]="onRequestReceived"
    [onNewFullDataReceived]="onFullDataReceived"></app-markdown-result>
</div>
<div>
  <span
    *ngIf="finalContent"
    (click)="onCopyClicked()"
    class="m-2 badge badge-soft-primary py-2"
    style="cursor: pointer"
    [appCopyToClipboard]="finalContent"
    ><i class="mdi mdi-clipboard" *ngIf="!isCopyClicked"></i> <i class="mdi mdi-clipboard-check" *ngIf="isCopyClicked"></i>&nbsp;&nbsp;{{
      'app_pages.tools.list.copy_result' | translate
    }}</span
  >
  <!-- <span *ngIf="containsCode()" class="m-2 badge badge-soft-secondary py-2">{{ 'app_pages.tools.list.contains_code' |
    translate }}</span>
  <span *ngIf="containsList()" class="m-2 badge badge-soft-primary py-2">{{ 'app_pages.tools.list.contains_list' |
    translate }}</span> -->
</div>
<div
  *ngIf="promptResult?.usage?.prompt_tokens || promptResult?.usage?.completion_tokens || promptResult?.usage?.total_tokens"
  class="alert alert-warning border-0 d-flex align-items-center mb-0 mt-2"
  role="alert">
  <i class="uil uil-exclamation-triangle font-size-16 text-warning me-2"></i>
  <div class="flex-grow-1">
    {{ 'app_pages.tools.list.this_prompt_used' | translate }}

    <ng-container *ngIf="promptResult?.usage?.prompt_tokens">
      {{ promptResult?.usage?.prompt_tokens }} {{ 'app_pages.tools.list.prompt_tokens' | translate }} &amp;
    </ng-container>
    <ng-container *ngIf="promptResult?.usage?.completion_tokens"
      >{{ promptResult?.usage?.completion_tokens }} {{ 'app_pages.tools.list.completion_tokens' | translate }}</ng-container
    >
    <span *ngIf="timeDisplay">
      {{ 'app_labels.and' | translate }} {{ 'app_labels.took' | translate }} {{ timeDisplay }}
      {{ 'app_labels.to_execute' | translate }}</span
    >.

    <ng-container *ngIf="promptResult?.usage?.total_tokens">
      {{ 'app_labels.a_total_of' | translate }} <b>{{ promptResult?.usage?.total_tokens }}</b>
      {{ 'app_pages.tools.list.tokens_were_used' | translate }}.</ng-container
    >
  </div>
</div>
