import { environment } from '../../environments/environment';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { throwError as observableThrowError } from 'rxjs';
export class RestBaseService {
  protected apiUrl: string;
  constructor(protected http: HttpClient) {
    this.apiUrl = environment.apiUrl;
  }

  protected handleError(error: HttpErrorResponse | any) {
    // console.log(error);
    // let errMsg: string;
    // if (error instanceof HttpErrorResponse) {
    //   errMsg = error.error ? error.error : error.message;
    // } else {
    //   errMsg = error.message ? error.message : error.toString();
    // }
    // console.error(errMsg);
    return observableThrowError(error);
  }
}
