import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getIdFirstPart',
})
export class GetIdFirstPartPipe implements PipeTransform {
  transform(id: string): string {
    if (!id) {
      return null;
    }
    return id.split('-')[0];
  }
}
