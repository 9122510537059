import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';
import { AuthenticationService } from '../services';
import { User } from '../models';

@Injectable({
  providedIn: 'root',
})
export class PlanActiveGuard implements CanActivate {
  constructor(private _router: Router, private _authservice: AuthenticationService) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    if (this.isPlanActive(this._authservice.userValue)) {
      return true;
    }
    //refresh token and try again.
    this._authservice.refreshToken().subscribe({
      next: (user: User) => {
        if (this.isPlanActive(user)) {
          return true;
        }
        let isStripeCustomer = user.externalCustomerId != null;
        if (!isStripeCustomer) this._router.navigate(['/onboarding']);
        if (isStripeCustomer && !user.isPlanActive) this._router.navigateByUrl('/settings/billing', { replaceUrl: true });
        return false;
      },
      error: () => {},
    });
    return true;
  }

  isPlanActive(user: User): boolean {
    if (!user) {
      return false;
    }
    if (user.isPlanActive) {
      return true;
    }
    return false;
  }
}
