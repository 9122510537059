import { SessionService } from './../services/session.service';
import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
const { v4: uuidv4 } = require('uuid');
import { environment } from '@environments/environment';
import { AuthenticationService } from '@core-services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  constructor(private authenticationService: AuthenticationService, private sessionService: SessionService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    // add auth header with jwt if user is logged in and request is to the api url
    const user = this.authenticationService.userValue;
    const isLoggedIn = user?.jwtToken;
    const isApiUrl = request.url.startsWith(environment.apiUrl);
    const correlationId = uuidv4();
    if (isLoggedIn && isApiUrl) {
      request = request.clone({
        setHeaders: {
          Authorization: `Bearer ${user.jwtToken}`,
          'X-Correlation-Id': correlationId,
          'X-Session-Id': this.sessionService.getSessionId(),
        },
      });
    }

    return next.handle(request);
  }
}
