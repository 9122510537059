import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'isDateExpired',
})
export class IsDateExpiredPipe implements PipeTransform {
  transform(dateIsoString: string): boolean {
    if (!dateIsoString) {
      return true;
    }
    const providedDate = new Date(dateIsoString);
    const currentDate = Date.now();
    return providedDate.getTime() < currentDate;
  }
}
