import { WorkspaceRole } from '@app/core/models/response';

export const WorkspaceRoleToLabel = {
  [WorkspaceRole.Owner]: 'app_pages.prompts.list.owner',
  [WorkspaceRole.Admin]: 'app_pages.prompts.list.admin',
  [WorkspaceRole.Engineer]: 'app_pages.prompts.list.engineer',
  [WorkspaceRole.Reader]: 'app_pages.prompts.list.reader',
  [WorkspaceRole.Guest]: 'app_pages.prompts.list.guest',
  [WorkspaceRole.None]: '',
};
