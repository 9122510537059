import { WorkspaceRole } from '@app/core/models/response';
import { WorkspaceAction } from '@app/core/models/workspace-action.enum';

export const ActionToRolesMap: { [key: string]: WorkspaceRole[] } = {
  [WorkspaceAction.RunPrompt]: [
    WorkspaceRole.Admin,
    WorkspaceRole.Engineer,
    WorkspaceRole.Reader,
    WorkspaceRole.Guest,
    WorkspaceRole.Owner,
  ],
  [WorkspaceAction.ReadPromptDetails]: [WorkspaceRole.Admin, WorkspaceRole.Engineer, WorkspaceRole.Reader, WorkspaceRole.Owner],
  [WorkspaceAction.AddPromptsVersions]: [WorkspaceRole.Admin, WorkspaceRole.Engineer, WorkspaceRole.Owner],
  [WorkspaceAction.EnableDisableAPI]: [WorkspaceRole.Admin, WorkspaceRole.Owner],
  [WorkspaceAction.EditExistingVersions]: [WorkspaceRole.Admin, WorkspaceRole.Owner],
  [WorkspaceAction.DeletePrompt]: [WorkspaceRole.Admin, WorkspaceRole.Owner],
  [WorkspaceAction.WorkspaceManagement]: [WorkspaceRole.Owner],
  // Personas
  [WorkspaceAction.OpenPersonaInPlayground]: [WorkspaceRole.Owner, WorkspaceRole.Admin, WorkspaceRole.Engineer, WorkspaceRole.Reader],
  [WorkspaceAction.StartPersonaChat]: [
    WorkspaceRole.Owner,
    WorkspaceRole.Admin,
    WorkspaceRole.Engineer,
    WorkspaceRole.Reader,
    WorkspaceRole.Guest,
  ],
  [WorkspaceAction.EditPersona]: [WorkspaceRole.Owner, WorkspaceRole.Admin, WorkspaceRole.Engineer],
  [WorkspaceAction.ActivateDeactivatePersona]: [WorkspaceRole.Admin, WorkspaceRole.Owner],
  [WorkspaceAction.PersonaApiIntegration]: [WorkspaceRole.Admin, WorkspaceRole.Owner],
};
