import { Pipe, PipeTransform } from '@angular/core';
import { DatePipe } from '@angular/common';

@Pipe({
  name: 'time',
})
export class TimePipe implements PipeTransform {
  constructor(private datePipe: DatePipe) {}
  transform(initialDate: Date): string {
    if (!initialDate) {
      return '';
    }
    const date = new Date(initialDate);
    if (!initialDate) {
      return '';
    }
    const today = new Date();
    const todayStartDay = today.setUTCHours(0, 0, 0, 0);
    const isBeforeToday = todayStartDay > this.getTimestamp(date);
    const format = isBeforeToday ? 'dd-MMM-YYYY' : 'HH:mm';
    return this.datePipe.transform(initialDate, format);
  }

  getTimestamp(date: Date): number {
    return Date.parse(date.toString());
  }
}
