<div class="card shadow-none border h-100 pointer" (click)="openClicked.emit()">
  <div class="card-body p-3">
    <div class="d-flex align-items-center">
      <!-- <div class="persona-sm me-3 mb-3">
        <div class="persona-title bg-transparent rounded">
          <i class="fas fa-folder font-size-24 text-warning"></i>
        </div>
      </div> -->
      <div class="overflow-hidden me-auto">
        <h5 class="font-size-14 text-truncate m-0 mb-2">
          <a href="javascript: void(0);" class="text-body">{{ name }}</a>
        </h5>
        <p class="text-muted text-truncate mb-0"> {{ additionalInfo }} </p>
      </div>
    </div>
    <div style="float: right" *ngIf="status">
      <span class="badge badge-soft-{{ statusColor }}" [ngClass]="{ 'mt-4': !additionalInfo }">{{ status }}</span>
    </div>
  </div>
</div>
