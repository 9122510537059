<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/sq-icon.svg" alt="" height="44" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark-no-caption.svg" alt="" height="44" />
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/sq-icon.svg" alt="" height="44" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light-no-caption.svg" alt="" height="44" />
          </span>
        </a>
      </div>

      <button
        type="button"
        class="btn btn-sm px-3 font-size-16 d-lg-none header-item"
        data-bs-toggle="collapse"
        data-bs-target="#topnav-menu-content"
        (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>

      <!-- <div class="dropdown d-none d-lg-block ms-2" ngbDropdown>
                <button type="button" class="btn header-item" data-bs-toggle="dropdown" aria-haspopup="false"
                    aria-expanded="false" ngbDropdownToggle>
                    <span>{{'TOPBAR.COMPONENT.TEXT' | translate}}</span>
                    <i class="mdi mdi-chevron-down"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-xl p-2" ngbDropdownMenu>
                    <div class="row">
                        <div class="col-md-6">
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.COMPONENT.LIST.ALERTS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.COMPONENT.LIST.BUTTONS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.COMPONENT.LIST.CARDS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.COMPONENT.LIST.DROPDOWNS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.COMPONENT.LIST.LIGHTBOX' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.COMPONENT.LIST.MODALS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.COMPONENT.LIST.RANGESLIDER' | translate}}</a>
                        </div>
                        <div class="col-md-6">
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.COMPONENT.LIST.PROGRESSBARS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.COMPONENT.LIST.SWEETALERTS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.COMPONENT.LIST.TABS&ACCODIONS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.COMPONENT.LIST.TYPOGRAPHY' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.COMPONENT.LIST.GENERAL' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.COMPONENT.LIST.RATING' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.COMPONENT.LIST.NOTIFICATIONS' | translate}}</a>
                        </div>
                    </div>
                </div>
            </div> -->

      <!-- <div class="dropdown dropdown-mega d-none d-lg-block" ngbDropdown>
                <button type="button" class="btn header-item" data-bs-toggle="dropdown" aria-haspopup="false"
                    aria-expanded="false" ngbDropdownToggle>
                    <span>{{'TOPBAR.CATEGORIES.TEXT' | translate}}</span>
                    <i class="mdi mdi-chevron-down"></i>
                </button>
                <div class="dropdown-menu p-2 dropdown-megamenu" ngbDropdownMenu>
                    <div class="row">
                        <div class="col">
                            <h5 class="font-size-14 mx-4 mt-2">{{'TOPBAR.CATEGORIES.LIST.COMPUTERSACCESSORIES' | translate}}</h5>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.LAPTOPSTABLETS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.DESKTOPCOMPUTERS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.NETWORKINGPRODUCTS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.MONITORS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.BAGS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.BATTERIES' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.CHARGES' | translate}}</a>
                        </div>
                        <div class="col">
                            <h5 class="font-size-14 ms-4 mt-2">{{'TOPBAR.CATEGORIES.LIST.SMARTPHONES' | translate}}</h5>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.APPLEIPHONE' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.ANDROID' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.PHABLETS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.APPLEIPAD' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.ANDROIDTABLETS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.TABLETS' | translate}}</a>
                        </div>
                        <div class="col">
                            <h5 class="font-size-14 ms-4 mt-2">{{'TOPBAR.CATEGORIES.LIST.TELEVISION' | translate}}</h5>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.TV' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.HOME' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.DVDPLAYERS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.DVDVCR' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.PROJECTORS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.PROJECTIONSCREENS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.SATELITE' | translate}}</a>
                        </div>
                        <div class="col">
                            <h5 class="font-size-14 ms-4 mt-2">{{'TOPBAR.CATEGORIES.LIST.CAMERAS' | translate}}</h5>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.POINT' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.DSLRCAMERAS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.MIRRORLESS' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.BODY' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.CAMERALENSES' | translate}}</a>
                            <a class="dropdown-item" href="javascript:void(0);">{{'TOPBAR.CATEGORIES.LIST.VIDEOSTUDIO' | translate}}</a>
                        </div>
                        <div class="col">
                            <div class="py-lg-2 pr-lg-2">
                                <img src="assets/images/illustrator/2.png" alt="" class="img-fluid mx-auto"
                                    style="max-height: 250px;">
                            </div>
                        </div>
                    </div>
                </div>
            </div> -->
    </div>
  </div>
  <div class="topnav">
    <div class="container-fluid">
      <nav class="navbar navbar-light navbar-expand-lg topnav-menu">
        <div class="collapse navbar-collapse" id="topnav-menu-content">
          <ul class="navbar-nav">
            <ng-container *ngFor="let item of menuItems">
              <li class="nav-item dropdown">
                <a
                  class="nav-link dropdown-toggle arrow-none"
                  *ngIf="!item.subItems"
                  (click)="onMenuClick($event)"
                  href="javascript: void(0);"
                  id="topnav-components"
                  [routerLink]="item.link"
                  role="button">
                  <i-feather name="{{ item.icon }}"></i-feather>{{ item.label | translate }}
                  <div class="arrow-down" *ngIf="hasItems(item)"></div>
                </a>
                <a
                  *ngIf="item.subItems"
                  class="nav-link dropdown-toggle arrow-none"
                  (click)="onMenuClick($event)"
                  href="javascript: void(0);"
                  id="topnav-components"
                  role="button">
                  <i-feather name="{{ item.icon }}"></i-feather>
                  {{ item.label | translate }}
                  <div class="arrow-down"></div>
                </a>

                <div
                  class="dropdown-menu"
                  [ngClass]="{ 'dropdown-mega-menu-xl': item.subItems.length > 11 }"
                  aria-labelledby="topnav-dashboard"
                  *ngIf="hasItems(item)">
                  <ng-template ngFor let-i="index" let-subitem [ngForOf]="item.subItems">
                    <a
                      *ngIf="item.subItems.length < 11 && !hasItems(subitem)"
                      class="col dropdown-item side-nav-link-ref"
                      [routerLink]="subitem.link"
                      routerLinkActive="active"
                      [routerLinkActiveOptions]="{ exact: true }">
                      {{ subitem.label | translate }}
                    </a>
                    <div *ngIf="item.subItems.length > 11">
                      <div *ngIf="i % 3 === 0" class="row">
                        <div class="col-lg-4">
                          <a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="subitem.link">{{
                            item.subItems[i].label | translate
                          }}</a>
                        </div>
                        <div class="col-lg-4">
                          <a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[i + 1]?.link">{{
                            item.subItems[i + 1]?.label | translate
                          }}</a>
                        </div>
                        <div class="col-lg-4">
                          <a class="dropdown-item side-nav-link-ref" routerLinkActive="active" [routerLink]="item.subItems[i + 2]?.link">{{
                            item.subItems[i + 2]?.label | translate
                          }}</a>
                        </div>
                      </div>
                    </div>
                    <div class="dropdown" *ngIf="hasItems(subitem)">
                      <a class="dropdown-item dropdown-toggle" href="javascript: void(0);" (click)="onMenuClick($event)"
                        >{{ subitem.label | translate }}
                        <div class="arrow-down"></div>
                      </a>
                      <div class="dropdown-menu">
                        <ng-template ngFor let-subSubitem [ngForOf]="subitem.subItems">
                          <a
                            class="dropdown-item side-nav-link-ref"
                            *ngIf="!hasItems(subSubitem)"
                            href="javascript: void(0);"
                            [routerLink]="subSubitem.link"
                            routerLinkActive="active"
                            >{{ subSubitem.label | translate }}</a
                          >
                          <div class="dropdown" *ngIf="hasItems(subSubitem)">
                            <a class="dropdown-item dropdown-toggle" href="javascript: void(0);" (click)="onMenuClick($event)"
                              >{{ subSubitem.label | translate }}
                              <div class="arrow-down"></div>
                            </a>
                            <div class="dropdown-menu">
                              <ng-template ngFor let-subSubSubitem [ngForOf]="subSubitem.subItems">
                                <a
                                  class="dropdown-item side-nav-link-ref"
                                  href="javascript: void(0);"
                                  [routerLink]="subSubSubitem.link"
                                  routerLinkActive="active"
                                  >{{ subSubSubitem.label | translate }}</a
                                >
                              </ng-template>
                            </div>
                          </div>
                        </ng-template>
                      </div>
                    </div>
                  </ng-template>
                </div>
              </li>
            </ng-container>
          </ul>
        </div>
      </nav>
    </div>
  </div>
</header>
