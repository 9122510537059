import { Input, Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'smallImageResolution',
})
export class SmallImageResolutionPipe implements PipeTransform {
  @Input() smallImageResolution: 100 | 200 = 100;

  transform(imageName: string): string {
    const parts = imageName.split('.');
    return parts[0] + '_' + this.smallImageResolution + '.' + parts[1];
  }
}
