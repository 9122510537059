import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'convertUnixToTime',
})
export class ConvertUnixToTimePipe implements PipeTransform {
  transform(unixTimestamp: number): Date {
    return new Date(unixTimestamp * 1000);
  }
}
