<nav aria-label="breadcrumb">
  <ol class="breadcrumb">
    <ng-container *ngFor="let item of breadcrumbItems">
      <li class="breadcrumb-item" [ngClass]="{ active: item.active == true, pointer: item.link }">
        <a *ngIf="item?.link; else label" [href]="item.link">{{ item.label }}</a>
        <ng-template #label>{{ item.label }}</ng-template>
      </li>
    </ng-container>
  </ol>
</nav>
