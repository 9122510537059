import { Pipe, PipeTransform } from '@angular/core';
import { TimePickerData } from '../components/range-datepicker/range-datepicker.component';

@Pipe({
  name: 'formatTimePickerData',
})
export class FormatTimePickerDataPipe implements PipeTransform {
  transform(time: TimePickerData, showTime = true): string {
    if (!showTime) {
      return '';
    }
    if (!time) {
      return '00:00:00';
    }
    return `0${time.hour}`.slice(-2) + ':' + `0${time.minute}`.slice(-2) + ':00';
  }
}
