import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-navigative-breadcrumbs',
  templateUrl: './navigative-breadcrumbs.component.html',
  styleUrls: ['./navigative-breadcrumbs.component.scss'],
})
export class NavigativeBreadcrumbsComponent {
  @Input() breadcrumbItems!: Array<{
    active?: boolean;
    label?: string;
    link?: string;
  }>;
}
