import { PlanResponse, UserMiniResponse } from '.';

export class OrganizationResponse {
  organizationId: string;
  organizationName: string;
  isActive: boolean;
  ownerUser: UserMiniResponse;
  planId: string;
  externalCustomerId: string;
  subscriptionId: string;
  cancelAt: Date;
  cancelAtPeriodEnd: boolean;
  trialStart: Date;
  trialEnd: Date;
  status: string;
  isPlanActive: boolean;
  plan: PlanResponse;
}

export class SessionLink {
  url: string;
}
