import { WorkspaceRole } from '@core/models/response';

export class CreateWorkspaceRequest {
  workspaceName: string;
  workspaceDescription?: string;
}
export class UpdateWorkspaceRequest {
  workspaceName?: string;
  workspaceDescription?: string;
  workspaceId: string;
  isActive?: boolean;
}

export class CreateWorkspaceUser {
  workspaceId: string;
  userId: string;
  workspaceRoleId: WorkspaceRole;
}

export class AttachPromptToWorkspaceRequest {
  workspaceId: string;
  promptId: string;
  promptVersionId: string;
}
