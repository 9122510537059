import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getObjectField',
})
export class GetObjectFieldPipe implements PipeTransform {
  transform(object: Record<string, any>, path: string): any {
    if (!path.includes('.')) {
      return object[path];
    }
    const segments = path.split('.');
    let result = object;

    for (const segment of segments) {
      if (result && typeof result === 'object' && segment in result) {
        result = result[segment];
      } else {
        return null;
      }
    }

    return result;
  }
}
