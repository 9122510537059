export class OrganizationKeyResponse {
  organizationId: string;
  organizationKeyName: string;
  organizationKeyDescription: string;
  organizationKeyId: string;
  organizationKeyValue: string;
  isActive: boolean;
  createdAt: Date;
  createdBy: string;
  modifiedAt: Date;
}
