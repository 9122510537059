import { MenuItem } from './menu.model';
import { RequiredFeature, UserRoleEnum } from '@shared/consts/user-role.enum';

export const MENU: MenuItem[] = [
  {
    id: 1,
    label: 'app_menu.tools.text',
    isTitle: true,
  },
  {
    id: 2,
    label: 'app_menu.tools.items.composer',
    icon: 'edit-3',
    link: '/tools/prompt-playground',
    triggerId: 'composer-menu-item',
  },
  {
    id: 26,
    label: 'app_menu.tools.items.persona_composer',
    icon: 'message-square',
    link: '/tools/persona-playground',
    triggerId: 'persona-composer-menu-item',
  },
  // {
  //   id: 4,
  //   label: 'app_menu.tools.items.utilities',
  //   icon: 'command',
  //   link: '/tools/utilities',
  //   triggerId: 'utilities-menu-item',
  // },
  {
    id: 10,
    label: 'app_menu.templates.text',
    isTitle: true,
  },
  {
    id: 11,
    label: 'app_menu.prompts.items.my-prompts',
    icon: 'lock',
    link: '/prompts',
    triggerId: 'my-prompts-menu-item',
  },
  {
    id: 18,
    label: 'app_menu.workspaces.items.my-workspaces',
    icon: 'folder',
    link: '/workspaces',
    triggerId: 'my-workspaces-menu-item',
  },
  {
    id: 19,
    label: 'app_menu.dataspaces.items.my-dataspaces',
    icon: 'hard-drive',
    link: '/dataspaces',
    requiredFeature: [RequiredFeature.Dataspaces],
    triggerId: 'dataspaces-menu-item',
  },

  {
    id: 3,
    label: 'app_menu.tools.items.chat',
    icon: 'message-circle',
    link: '/tools/chat-studio',
    triggerId: 'chat-studio-menu-item',
  },
  {
    id: 12,
    label: 'app_menu.prompts.items.prompt-library',
    icon: 'book',
    link: '/prompts/library',
    triggerId: 'prompts-library-menu-item',
  },
  // {
  //   id: 13,
  //   label: 'app_menu.fine-tuning.text',
  //   isTitle: true,
  // },
  // {
  //   id: 14,
  //   label: 'app_menu.fine-tuning.items.uploads',
  //   icon: 'file-plus',
  //   link: '/fine-tuning/uploads',
  //   triggerId: 'fine-tuning-uploads-menu-item',
  // },
  // {
  //   id: 15,
  //   label: 'app_menu.fine-tuning.items.training',
  //   icon: 'cpu',
  //   link: '/fine-tuning/training',
  //   triggerId: 'fine-tuning-models-menu-item',
  // },
  // {
  //   id: 16,
  //   label: 'app_menu.fine-tuning.items.models',
  //   icon: 'database',
  //   link: '/fine-tuning/models',
  //   triggerId: 'fine-tuning-models-menu-item',
  // },
  // {
  //     id: 8,
  //     label: 'app_menu.EMAIL.text',
  //     icon: 'mail',
  //     subItems: [
  //         {
  //             id: 9,
  //             label: 'app_menu.EMAIL.LIST.INBOX',
  //             link: '/email/inbox',
  //             parentId: 8
  //         },
  //         {
  //             id: 10,
  //             label: 'app_menu.EMAIL.LIST.READEMAIL',
  //             link: '/email/read/1',
  //             parentId: 8
  //         }
  //     ]
  // },
  // {
  //   id: 17,
  //   label: 'app_menu.workspaces.text',
  //   isTitle: true,
  // },
  // {
  //   id: 18,
  //   label: 'app_menu.workspaces.items.my-workspaces',
  //   icon: 'folder',
  //   link: '/workspaces',
  //   triggerId: 'my-workspaces-menu-item',
  // },
  // {
  //   id: 19,
  //   label: 'app_menu.workspaces.items.preferences',
  //   icon: 'sliders',
  //   link: '/workspaces/preferences',
  //   triggerId: 'preferences-menu-item',
  // },
  // {
  //   id: 16,
  //   label: 'app_menu.data-spaces.text',
  //   isTitle: true,
  // },
  // {
  //   id: 17,
  //   label: 'app_menu.data-spaces.items.my-dataspaces',
  //   icon: 'lock',
  //   link: '/dataspaces',
  // },
  // {
  //   id: 18,
  //   label: 'app_menu.data-spaces.items.preferences',
  //   icon: 'sliders',
  //   link: '/dataspaces/preferences',
  // },
  {
    id: 5,
    label: 'app_menu.home.text',
    isTitle: true,
  },
  // {
  //   id: 6,
  //   label: 'app_menu.home.items.activity',
  //   icon: 'activity',
  //   link: '/home/',
  //   triggerId: 'activity-menu-item',
  // },
  {
    id: 7,
    label: 'app_menu.home.items.audit-log',
    icon: 'file-text',
    link: '/audit-log',
    triggerId: 'audit-log-menu-item',
  },
  {
    id: 9,
    label: 'app_menu.usage-reports.items.my-usage-reports',
    icon: 'file-text',
    link: '/usage-reports',
    requiredFeature: [RequiredFeature.UsageReports],
    triggerId: 'usage-reports-menu-item',
  },
  {
    id: 9,
    label: 'app_menu.feedback-reports.items.my-feedback-reports',
    icon: 'file-text',
    link: '/feedback-reports',
    requiredFeature: [RequiredFeature.FeedbackReports],
    triggerId: 'persona-reports-menu-item',
  },
  {
    id: 8,
    label: 'app_menu.home.items.feedback-log',
    icon: 'user-check',
    link: '/feedback-log',
    triggerId: 'feedback-log-menu-item',
  },
  // {
  //   id: 9,
  //   label: 'app_menu.home.items.usage',
  //   icon: 'pie-chart',
  //   link: '/home/usage',
  //   triggerId: 'usage-menu-item',
  // },
  {
    id: 20,
    label: 'app_menu.settings.text',
    isTitle: true,
  },
  {
    id: 25,
    label: 'app_menu.provider-keys.text',
    icon: 'key',
    link: '/provider-keys',
    requiredRoles: [UserRoleEnum.Owner],
    triggerId: 'provider-keys-menu-item',
  },
  {
    id: 21,
    label: 'app_menu.settings.items.api-keys',
    icon: 'code',
    link: '/settings/api-keys',
    triggerId: 'api-keys-menu-item',
  },
  {
    id: 24,
    label: 'app_menu.settings.items.org',
    icon: 'users',
    link: '/org-settings/users',
    triggerId: 'users-menu-item',
  },
  {
    id: 22,
    label: 'app_menu.settings.items.billing',
    icon: 'credit-card',
    link: '/settings/billing',
    requiredRoles: [UserRoleEnum.Owner],
    triggerId: 'billing-menu-item',
  },
  // {
  //   id: 23,
  //   label: 'app_menu.settings.items.subscriptions',
  //   icon: 'external-link',
  //   link: '/manage-subscription',
  //   requiredRoles: [UserRoleEnum.Owner],
  //   triggerId: 'subscriptions-menu-item',
  // },
];
