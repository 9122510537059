import { Injectable, Injector, TemplateRef } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { DEFAULT_DANGER_ALERT_TEXT, DEFAULT_DANGER_ALERT_TRANSLATION_KEY } from '@shared/consts/default-danger-alert-text.const';

export interface ToastInfo {
  textOrTpl: string | TemplateRef<any>;
  classname?: string;
  delay?: number;
  type: ToastType;
}
export enum ToastType {
  Success = 'Success',
  Danger = 'Danger',
  Warning = 'Warning',
}
@Injectable({
  providedIn: 'root',
})
export class ToastService {
  toasts: ToastInfo[] = [];

  constructor(private readonly injector: Injector) {}

  show(textOrTpl: string | TemplateRef<any>, type: ToastType, options: any = {}) {
    const toastToPush = {
      textOrTpl,
      ...options,
      type: type,
    };
    this.toasts.push(toastToPush);
  }

  remove(toast: any) {
    this.toasts = this.toasts.filter(t => t !== toast);
  }

  showDefaultError() {
    const translateService = this.injector.get(TranslateService);
    const message = translateService.instant(DEFAULT_DANGER_ALERT_TRANSLATION_KEY);
    this.show(message, ToastType.Danger);
  }

  showTranslationError(message: string) {
    const translateService = this.injector.get(TranslateService);
    const translatedMsg = translateService.instant(message);
    message ? this.show(translatedMsg, ToastType.Danger) : this.showDefaultError();
  }
}
