<!-- start page title -->
<div class="row">
  <div class="col-12">
    <div class="page-title-box">
      <div class="d-flex align-items-center justify-content-between" [class.mb-1]="!!subtitle">
        <h4 class="mb-0">
          <i-feather *ngIf="icon" name="{{ icon }}" class="me-1 nav-icon"></i-feather>
          {{ title }}
        </h4>
        <div class="page-title-right">
          <ol class="breadcrumb m-0">
            <ng-container *ngFor="let item of breadcrumbItems">
              <li class="breadcrumb-item" [ngClass]="{ active: item.active === true }">
                {{ item.label }}
              </li>
            </ng-container>
          </ol>
        </div>
      </div>
      <p class="page-subtitle mb-0 mt-3" *ngIf="subtitle">{{ subtitle }}</p>
    </div>
  </div>
</div>
<!-- end page title -->
