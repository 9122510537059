import { AISystemNameResponse } from '@core/models/response';

export class CreatePersonaRequest {
  personaName: string;
  personaDescription?: string;
  model?: string;
  aiSystem?: AISystemNameResponse;
  personaSystemMessage: string;
  personaAssistantMessage?: string;
  personaReplacements?: Record<string, any>;
  personaMetadata?: Record<string, any>;
  personaConfiguration?: Record<string, any>;
  isActive: boolean;
  isSecret?: boolean;
  isApiEnabled: boolean;
  personaImageUrl?: string;
}

export type UpdatePersonaRequest = Partial<CreatePersonaRequest> & { personaId: string };

export class UpdateConversationRequest {
  conversationId: string;
  conversationName?: string;
  conversationDescription?: string;
  isActive?: boolean;
}

export enum PatchChatAction {
  SetName = 'set-conversation-name',
  SetActive = 'set-active',
}

export enum PatchPersonaAction {
  SetPersona = 'set-persona',
  SetApiEnabled = 'set-api-enabled',
  SetActive = 'set-active',
}
export class UpdateMessageScoreRequest {
  messageId: string;
  score: Score;
}

export enum Score {
  Up = 10,
  Down = -10,
  Default = 0,
}
