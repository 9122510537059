import { OpenAiModels } from '@core/models/shared.model';

export interface AppConfigModel {
  appOffline: boolean;
  forceAppUpdate: boolean;
  appVersion: string;
  privacyLink: string;
  termsLink: string;
  faqLink: string;
  contactEmail: string;
  contactSubject: string;
  feedbackEmail: string;
  feedbackSubject: string;
  aiSystems: AISystem[];
  aiSystemModels: AISystemModel[];
}

export interface AISystem {
  name: AISystemName;
  config: AISystemConfig[];
  isActive: boolean;
  isChattable: boolean;
  defaultModel: OpenAiModels;
}

export interface AISystemConfig {
  name: string;
  description?: string;
  type?: ConfigType;
  default?: number | string;
  min?: number;
  max?: number;
}

export interface AISystemModel {
  system: string;
  model: OpenAiModels;
  family: string;
  description: string;
  max_tokens: number;
  update_type: string;
  expires_at: number;
  isActive: boolean;
  isChattable: boolean;
}

export enum AISystemName {
  OpenAi = 'open-ai',
  Anthropic = 'anthropic-claude',
  AzureOpenAi = 'azure-open-ai',
  GoogleGemini = 'google-gemini',
  GooglePalm = 'palm',
  Groq = 'groq',
  Cohere = 'cohere',
}

export enum ConfigType {
  Number = 'number',
  Boolean = 'boolean',
  Integer = 'integer',
}

export type AiSystemConfigs = Record<string, any>;
