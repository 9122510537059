import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { Router } from '@angular/router';

import { CookieService } from 'ngx-cookie-service';
import { TranslateService } from '@ngx-translate/core';

import { LanguageService } from '../../core/services/language.service';
import { AuthenticationService } from '@core-services/authentication.service';
import { User } from '@app/core/models';
import { LayoutMode } from '@core/models/layout-mode.enum';
import { EventService } from '@core/services/event.service';
import { LocalStorageClientService } from '@core/services/local-storage-client.service';
import { MixpanelService } from '@app/core/services/mixpanel.service';
import { MixpanelEvent } from '@app/core/models/mixpanel-event.model';
import { MixpanelVariable } from '@app/core/models/mixpanel-variable.model';

@Component({
  selector: 'app-topbar',
  templateUrl: './topbar.component.html',
  styleUrls: ['./topbar.component.scss'],
})

/**
 * Topbar Component
 */
export class TopbarComponent implements OnInit {
  @Output() mobileMenuButtonClicked = new EventEmitter();

  readonly layoutMode = LayoutMode;
  mode: string | undefined;
  element: any;
  flagvalue: any;
  cookieValue: any;
  countryName: any;
  valueset: any;
  authenticatedUser: User;
  currentLayoutMode: LayoutMode = LayoutMode.Light;

  constructor(
    private router: Router,
    public languageService: LanguageService,
    public _cookiesService: CookieService,
    public translate: TranslateService,
    private authService: AuthenticationService,
    private eventService: EventService,
    private localStorageClientService: LocalStorageClientService,
    private mixpanelService: MixpanelService
  ) {}

  /***
   * Language Listing
   */
  listLang = [
    { text: 'English', flag: 'assets/images/flags/us.jpg', lang: 'en' },
    { text: 'Spanish', flag: 'assets/images/flags/spain.jpg', lang: 'es' },
    { text: 'German', flag: 'assets/images/flags/germany.jpg', lang: 'de' },
    { text: 'Italian', flag: 'assets/images/flags/italy.jpg', lang: 'it' },
    { text: 'Russian', flag: 'assets/images/flags/russia.jpg', lang: 'ru' },
  ];

  @Output() settingsButtonClicked = new EventEmitter();

  ngOnInit(): void {
    this.checkLayoutMode();
    // Cookies wise Language set
    this.cookieValue = this._cookiesService.get('lang');
    const val = this.listLang.filter(x => x.lang === this.cookieValue);
    this.countryName = val.map(element => element.text);
    if (val.length === 0) {
      if (this.flagvalue === undefined) {
        this.valueset = 'assets/images/flags/us.jpg';
      }
    } else {
      this.flagvalue = val.map(element => element.flag);
    }
    this.authService.user.subscribe(x => {
      this.authenticatedUser = x;
    });
  }

  checkLayoutMode() {
    const savedLayoutMode = this.localStorageClientService.getSingleEntity('layoutMode') as LayoutMode;
    if (savedLayoutMode) {
      this.currentLayoutMode = savedLayoutMode;
      this.eventService.broadcast('changeMode', savedLayoutMode);
    }
  }

  /***
   * Language Value Set
   */
  setLanguage(text: string, lang: string, flag: string) {
    this.countryName = text;
    this.flagvalue = flag;
    this.cookieValue = lang;
    this.languageService.setLanguage(lang);
  }

  /**
   * Toggles the right sidebar
   */
  toggleRightSidebar() {
    this.settingsButtonClicked.emit();
  }

  /**
   * Toggle the menu bar when having mobile screen
   */
  toggleMobileMenu(event: any) {
    event.preventDefault();
    this.mobileMenuButtonClicked.emit();
  }

  /**
   * Logout the user
   */
  logout() {
    this.authService.logout();
  }

  changeLayoutMode() {
    if (this.currentLayoutMode === LayoutMode.Light) {
      this.currentLayoutMode = LayoutMode.Dark;
    } else {
      this.currentLayoutMode = LayoutMode.Light;
    }
    this.mixpanelService.track(MixpanelEvent.THEME_CHANGE, { [MixpanelVariable.THEME_TYPE]: this.currentLayoutMode });
    this.eventService.broadcast('changeMode', this.currentLayoutMode);
    this.localStorageClientService.setSingleEntity('layoutMode', this.currentLayoutMode);
  }

  tourApp() {
    this.mixpanelService.track(MixpanelEvent.TOUR_APP);
    (<any>window).Intercom('startTour', 477355);
  }
}
