import { Pipe, PipeTransform } from '@angular/core';
import { AuthenticationService } from '@app/core/services';
import { UserRoleEnum } from '../consts/user-role.enum';

@Pipe({
  name: 'isValidUserRole',
})
export class IsValidUserRolePipe implements PipeTransform {
  constructor(private authService: AuthenticationService) {}

  transform(requiredRoles: UserRoleEnum[]): boolean {
    if (!requiredRoles?.length) {
      return true;
    }
    const currentUser = this.authService.userSubject.getValue();
    if (!requiredRoles.find((r: any) => r === currentUser.organizationRoleId)) {
      return false;
    }
    return true;
  }
}
