<!-- <div class="no-providers-warning-bar bg-danger text-white text-center p-3 fz-16" *ngIf="isNoProvidersWarningVisible">
  <span>
    {{ 'app_pages.provider_keys.list.no_providers_warning' | translate }}.
    <a [routerLink]="['/provider-keys']" class="fw-bold text-white text-decoration-underline" href="javascript:void(0);">{{
      'app_pages.provider_keys.list.manage_providers' | translate
    }}</a>
  </span>
</div> 
<div class="main-page-wrapper" [class.warning-enabled]="isNoProvidersWarningVisible">
  -->

<div class="main-page-wrapper">
  <!-- Vertical layout -->
  <app-vertical *ngIf="isVerticalLayoutRequested()"></app-vertical>

  <!-- horizontal layout -->
  <app-horizontal *ngIf="isHorizontalLayoutRequested()"></app-horizontal>
</div>
