import { Component, Input } from '@angular/core';
import { Router } from '@angular/router';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { PromptResponse, WorkspaceResponse } from '@core/models/response';

@Component({
  selector: 'app-go-to-workspace',
  templateUrl: './go-to-workspace.component.html',
  styleUrls: ['./go-to-workspace.component.scss'],
})
export class GoToWorkspaceComponent {
  @Input() prompt: PromptResponse;
  @Input() workspace: WorkspaceResponse;

  constructor(private router: Router, public activeModal: NgbActiveModal) {}
  go() {
    this.router.navigate(['/workspaces/prompts', this.workspace?.workspaceId]);
    this.activeModal.dismiss();
  }
  cancel() {
    this.activeModal.dismiss();
  }
}
