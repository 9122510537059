<header id="page-topbar">
  <div class="navbar-header">
    <div class="d-flex">
      <!-- LOGO -->
      <div class="navbar-brand-box">
        <a routerLink="/" class="logo logo-dark">
          <span class="logo-sm">
            <img src="assets/images/sq-icon.svg" alt="" height="44" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-dark-no-caption.svg" alt="" height="44" />
          </span>
        </a>

        <a routerLink="/" class="logo logo-light">
          <span class="logo-sm">
            <img src="assets/images/sq-icon.png" alt="" height="44" />
          </span>
          <span class="logo-lg">
            <img src="assets/images/logo-light-no-caption.svg" alt="" height="44" />
          </span>
        </a>
      </div>
      <button type="button" class="btn btn-sm px-3 font-size-16 header-item vertical-menu-btn" (click)="toggleMobileMenu($event)">
        <i class="fa fa-fw fa-bars"></i>
      </button>
    </div>

    <div class="d-flex align-items-center">
      <div class="form-check form-switch" appTooltip [title]="'app_topbar.component.list.change_layout_theme' | translate">
        <!--                <label class="form-check-label" for="layout-theme">{{ currentLayoutMode === layoutMode.Light ? 'Light theme' : 'Dark theme' }}</label>-->
        <input class="form-check-input" type="checkbox" id="layout-theme" (click)="changeLayoutMode()" />
      </div>

      <!--      <div class="dropdown d-inline-block">-->
      <!--        <button type="button" class="btn header-item noti-icon right-bar-toggle" id="right-bar-toggle" (click)="toggleRightSidebar()">-->
      <!--          <i-feather class="icon-sm" name="settings"></i-feather>-->
      <!--        </button>-->
      <!--      </div>-->

      <div class="dropdown d-inline-block" ngbDropdown>
        <button
          type="button"
          class="btn header-item user text-start d-flex align-items-center"
          id="page-header-user-dropdown"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ngbDropdownToggle>
          <img
            class="rounded-circle header-profile-user cover-image"
            [src]="
              authenticatedUser?.profileImageUrl
                ? (authenticatedUser?.profileImageUrl | smallImageResolution | secure | async)
                : 'assets/images/user-60.png'
            " />
          <span class="ms-2 d-none d-sm-block user-item-desc">
            <span class="user-name"> {{ authenticatedUser.firstName }}</span>
            <span class="user-sub-title"> {{ authenticatedUser.role }}</span>
          </span>
        </button>
        <div class="dropdown-menu dropdown-menu-end pt-0" ngbDropdownMenu>
          <div class="p-3 bg-primary border-bottom">
            <h6 class="mb-0 text-white">
              {{ authenticatedUser.firstName }}
            </h6>
            <p class="mb-0 font-size-11 text-white-50 fw-semibold">
              {{ authenticatedUser.email }}
            </p>
          </div>
          <a class="dropdown-item" routerLink="/settings/account">
            <span class="align-middle">{{ 'app_menu.settings.items.account' | translate }}</span></a
          >
          <a class="dropdown-item" href="https://docs.hyperleap.ai" target="_blank">
            <span class="align-middle">{{ 'app_menu.pages.items.developer_docs' | translate }}</span></a
          >
          <a class="dropdown-item" id="beta-features">
            <span class="align-middle">{{ 'app_menu.pages.items.beta_features' | translate }}</span></a
          >
          <a class="dropdown-item" href="https://help.hyperleap.ai" target="_blank">
            <span class="align-middle">{{ 'app_menu.pages.items.help_docs' | translate }}</span></a
          >
          <a class="dropdown-item" href="javascript:void(0);" id="tour-app-trigger" (click)="tourApp()">
            <span class="align-middle">{{ 'app_menu.pages.items.tour_app' | translate }}</span></a
          >
          <div class="dropdown-divider"></div>
          <a class="dropdown-item" (click)="logout()"
            ><i class="mdi mdi-logout text-muted font-size-16 align-middle me-1"></i>
            <span class="align-middle">
              {{ 'app_menu.settings.items.logout' | translate }}
            </span></a
          >
        </div>
      </div>
    </div>
  </div>
</header>
