<ngb-toast
  class="custom-toast"
  *ngFor="let toast of toastService.toasts"
  [autohide]="true"
  [delay]="toast?.delay || 3000"
  (hidden)="toastService.remove(toast)">
  <div class="line" [class]="getLineClass(toast)"></div>
  <ng-template [ngIf]="isTemplate(toast)" [ngIfElse]="text">
    <ng-template [ngTemplateOutlet]="getTemplate(toast)"></ng-template>
  </ng-template>

  <ng-template #text>{{ toast.textOrTpl }}</ng-template>
</ngb-toast>
