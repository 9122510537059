import { createReducer, on } from '@ngrx/store';
import { AppConfigModel } from '@core/models/app-config.model';
import * as AppConfigsActions from './app-configs.actions';

export const appConfigsFeatureKey = 'appConfigs';
export interface AppConfigsState {
  configs: AppConfigModel | null;
}

export const initialState: AppConfigsState = {
  configs: null,
};

export const appConfigsReducer = createReducer(
  initialState,
  on(AppConfigsActions.setAppConfigs, (state, { configs }) => {
    return {
      ...initialState,
      configs,
    };
  })
);
