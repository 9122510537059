import { Pipe, PipeTransform } from '@angular/core';
import { WorkspaceResponse } from '@app/core/models/response';

@Pipe({
  name: 'filterEmptyWorkspaces',
})
export class FilterEmptyWorkspacesPipe implements PipeTransform {
  transform(
    workspaces: WorkspaceResponse[],
    hasToHideEmpty = true,
    filterField: keyof WorkspaceResponse = 'promptCount'
  ): WorkspaceResponse[] {
    return hasToHideEmpty ? workspaces.filter(value => !!value[filterField]) : workspaces;
  }
}
