import { UserMiniResponse } from '../response';

export class CreateOrganizationKeyRequest {
  organizationKeyName: string;
  organizationKeyDescription?: string;
}
export class UpdateOrganizationKeyRequest {
  organizationId: string;
  organizationKeyName?: string;
  organizationKeyDescription?: string;
  organizationKeyId: string;
  isActive?: boolean;
}
