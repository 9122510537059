import { Pipe, PipeTransform } from '@angular/core';
import { DataspaceResponse } from '@app/core/models/response';

@Pipe({
  name: 'filterEmptyDataspaces',
})
export class FilterEmptyDataspacesPipe implements PipeTransform {
  transform(
    Dataspaces: DataspaceResponse[],
    hasToHideEmpty = true,
  ): DataspaceResponse[] {
    return hasToHideEmpty 
    ? Dataspaces.filter(value =>  (value.documentCount + value.imageCount + value.websiteCount + value.stringCount) > 0) 
    : Dataspaces;
  }
}
