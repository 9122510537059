import { Pipe, PipeTransform } from '@angular/core';
import { SafeUrl } from '@angular/platform-browser';
import { Observable } from 'rxjs';
import { ImagesService } from '@core/services/images.service';

@Pipe({
  name: 'secure',
})
export class SecurePipe implements PipeTransform {
  constructor(private imagesService: ImagesService) {}

  transform(imageName: string): Observable<SafeUrl> {
    return this.imagesService.get(imageName);
  }
}
