import { Injectable } from '@angular/core';
const { v4: uuidv4 } = require('uuid');

@Injectable({
  providedIn: 'root',
})
export class SessionService {
  private sessionIdKey = 'sessionId';

  constructor() {}

  getSessionId(): string {
    let sessionId = localStorage.getItem(this.sessionIdKey);
    return sessionId;
  }

  generateSessionId() {
    var sessionId = uuidv4();
    localStorage.setItem(this.sessionIdKey, sessionId);
  }
}
