import { Component, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-eye-control',
  templateUrl: './eye-control.component.html',
  styleUrls: ['./eye-control.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: EyeControlComponent,
    },
  ],
})
export class EyeControlComponent implements ControlValueAccessor {
  @Input() iconUrl?: string = '';
  @Input() title?: string = '';
  @Input() controlName?: string = '';
  @Input() label?: string = '';
  disabled: boolean;

  value: string;

  isVisible = false;

  onChange = (valueString: string) => {};

  onTouched = () => {};

  writeValue(text: string) {
    this.value = text;
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  valueChange() {
    this.onChange(this.value);
  }
}
