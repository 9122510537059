import { CookieService } from 'ngx-cookie-service';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';

import { environment } from '@environments/environment';
import { AuthenticateRequestDto, User, VerificationRequestDto } from '@app/core/models';
import { CreateAccountRequest, JoinAccountRequest, LoginToAccountRequest } from '../models/request/auth';
import { MixpanelService } from './mixpanel.service';
import { MixpanelEvent } from '../models/mixpanel-event.model';

@Injectable({ providedIn: 'root' })
export class AuthenticationService {
  public userSubject: BehaviorSubject<User | null>;
  public user: Observable<User | null>;

  constructor(
    private router: Router,
    private http: HttpClient,
    private cookieService: CookieService,
    private mixpanelService: MixpanelService
  ) {
    this.userSubject = new BehaviorSubject<User | null>(null);
    this.user = this.userSubject.asObservable();
  }

  public get userValue() {
    return this.userSubject.value;
  }

  public updateUser(user: User) {
    this.userSubject.next({ ...this.userValue, ...user });
  }

  login(verificationRequestDto: VerificationRequestDto) {
    return this.http.post<any>(`${environment.apiUrl}/api/auth/authenticate`, verificationRequestDto, { withCredentials: true }).pipe(
      map((user: User) => {
        this.userSubject.next(user);
        // this.mixpanelService.identify(user.userId);
        this.mixpanelService.track(MixpanelEvent.LOGIN);
        this.startRefreshTokenTimer();
        return user;
      })
    );
  }

  sendOtp(authenticateRequestDto: LoginToAccountRequest) {
    return this.http.post<any>(`${environment.apiUrl}/api/auth/request-login`, authenticateRequestDto, { withCredentials: true }).pipe(
      map(results => {
        return results;
      })
    );
  }

  createAccount(createAccountRequest: CreateAccountRequest) {
    return this.http.post<any>(`${environment.apiUrl}/api/auth/request-signup`, createAccountRequest, { withCredentials: true }).pipe(
      map(results => {
        return results;
      })
    );
  }

  joinAccount(joinAccountRequest: JoinAccountRequest) {
    return this.http
      .post<any>(`${environment.apiUrl}/api/auth/request-join`, joinAccountRequest, {
        withCredentials: true,
      })
      .pipe(
        map(results => {
          return results;
        })
      );
  }

  getEmailFromInvite(inviteCode: string) {
    return this.http.get<any>(`${environment.apiUrl}/api/auth/get-email?inviteCode=${inviteCode}`, { withCredentials: true }).pipe(
      map(results => {
        return results;
      })
    );
  }
  logout() {
    if (this.userValue?.jwtToken) {
      this.http.post<any>(`${environment.apiUrl}/api/auth/revoke-credentials`, {}, { withCredentials: true }).subscribe(x => {
        this.handlePostLogout();
      });
    } else {
      this.handlePostLogout();
    }
  }

  refreshToken() {
    return this.http.post<any>(`${environment.apiUrl}/api/auth/refresh-credentials`, {}, { withCredentials: true }).pipe(
      map((user: User) => {
        this.userSubject.next(user);
        this.startRefreshTokenTimer();
        this.mixpanelService.identify(user.userId);
        return user;
      })
    );
  }

  // helper methods

  private refreshTokenTimeout?: NodeJS.Timeout;

  private startRefreshTokenTimer() {
    // parse json object from base64 encoded jwt token
    const jwtBase64 = this.userValue!.jwtToken!.split('.')[1];
    const jwtToken = JSON.parse(atob(jwtBase64));

    // set a timeout to refresh the token a minute before it expires
    const expires = new Date(jwtToken.exp * 1000);
    const timeout = expires.getTime() - Date.now() - 60 * 1000;
    this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
  }

  private stopRefreshTokenTimer() {
    clearTimeout(this.refreshTokenTimeout);
  }

  private handlePostLogout() {
    this.stopRefreshTokenTimer();
    this.userSubject.next(null);
    this.mixpanelService.track(MixpanelEvent.LOGOUT);
    this.router.navigate(['/account/login']);
  }
}
