<div class="modal-header">
  <h5 class="modal-title" id="addContactModalLabel">{{ 'app_pages.prompts.list.prompt_copied' | translate }}</h5>
  <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close" (click)="cancel()"></button>
</div>
<div class="modal-body">
  <div class="row">
    <div class="col-12">
      <b>{{ prompt?.promptName }}</b>
      {{ 'app_pages.prompts.list.successfully_copied_to' | translate }}
      <b>{{ workspace?.workspaceName }}</b>
      {{ 'app_pages.prompts.list.workspace' | translate }}
    </div>
  </div>
</div>

<div class="modal-footer">
  <button type="button" class="btn btn-light w-sm" (click)="cancel()">{{ 'app_actions.list.cancel' | translate }}</button>
  <button type="button" class="btn btn-primary w-sm" (click)="go()" id="btn-save-event">
    {{ 'app_pages.prompts.list.go_to_workspace' | translate }}<span aria-hidden="true"></span>
  </button>
</div>
