import { Injectable } from '@angular/core';
import { LayoutMode } from '../models/layout-mode.enum';

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  constructor() {}

  changeTheme(mode: LayoutMode) {
    switch (mode) {
      case LayoutMode.Light:
        document.body.setAttribute('data-sidebar', 'light');
        document.body.setAttribute('data-layout-mode', 'light');
        document.body.setAttribute('data-topbar', 'light');
        break;
      case LayoutMode.Dark:
        document.body.setAttribute('data-sidebar', 'dark');
        document.body.setAttribute('data-layout-mode', 'dark');
        document.body.setAttribute('data-topbar', 'dark');
        break;
      default:
        document.body.setAttribute('data-layout-mode', 'light');
        document.body.setAttribute('data-topbar', 'light');
        break;
    }
  }
}
