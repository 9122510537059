import { HighlightModule, HIGHLIGHT_OPTIONS } from 'ngx-highlightjs';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { EditorModule } from '@tinymce/tinymce-angular';
import { appInitializer, JwtInterceptor, ErrorInterceptor } from './core/helpers';
import { AuthenticationService } from '@core-services/authentication.service';
import { ClipboardButtonComponent, ClipboardOptions, MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { PagesModule } from './pages/pages.module';
import { PageActivatorService } from './core/guards/page-activator.service';
import { ToastrModule } from 'ngx-toastr';
import { MetaReducer, StoreModule } from '@ngrx/store';
import { environment } from '@env';
import { effects, reducers } from '@app/store';
import { EffectsModule } from '@ngrx/effects';
import { NgbToast } from '@ng-bootstrap/ng-bootstrap';
import { SharedModule } from '@shared/shared.module';
import { TagInputModule } from 'ngx-chips';
import { ReactiveFormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select';
import { DecimalPipe } from '@angular/common';
import { WebpackTranslateLoader } from './shared/services/webpack-translate-loader';
import hljs from 'highlight.js/lib/core';
import { RECAPTCHA_SETTINGS, RecaptchaFormsModule, RecaptchaModule, RecaptchaSettings } from 'ng-recaptcha';
import { ServiceWorkerModule } from '@angular/service-worker';

// AoT requires an exported function for factories
export function HttpLoaderFactory(httpClient: HttpClient) {
  return new TranslateHttpLoader(httpClient, 'assets/i18n/', '.json');
}

export const metaReducers: MetaReducer<any>[] = !environment.production ? [] : [];

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    TagInputModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    PagesModule,
    HttpClientModule,
    EditorModule,
    HighlightModule,
    RecaptchaModule,
    RecaptchaFormsModule,
    MarkdownModule.forRoot({
      loader: HttpClientModule,
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          highlight: (code: string, options: any) => {
            if (options.lang && hljs.getLanguage(options.lang)) {
              return hljs.highlight(code, options).value;
            }
            return hljs.highlightAuto(code).value;
          },
        },
      },
    }),
    TranslateModule.forRoot({
      defaultLanguage: 'en',
      loader: {
        provide: TranslateLoader,
        // useFactory: HttpLoaderFactory,
        useClass: WebpackTranslateLoader,
        deps: [HttpClient],
      },
    }),
    ToastrModule.forRoot(),
    StoreModule.forRoot(reducers, { metaReducers }),
    EffectsModule.forRoot(effects),
    NgbToast,
    SharedModule,
    NgSelectModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: !isDevMode(),
      // Register the ServiceWorker as soon as the application is stable
      // or after 30 seconds (whichever comes first).
      registrationStrategy: 'registerWhenStable:30000',
    }),
  ],
  providers: [
    { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthenticationService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    {
      provide: HIGHLIGHT_OPTIONS,
      useValue: {
        fullLibraryLoader: () => import('highlight.js'),
      },
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptcha.siteKey,
      } as RecaptchaSettings,
    },
    PageActivatorService,
    DecimalPipe,
  ],
  bootstrap: [AppComponent],
  exports: [TranslateModule],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
