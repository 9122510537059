import { Pipe, PipeTransform } from '@angular/core';
import { User } from '@app/core/models';
import { UserRoleEnum } from '../consts/user-role.enum';

@Pipe({
  name: 'filterOutOrganizationOwner',
})
export class FilterOutOrganizationOwnerPipe implements PipeTransform {
  transform(users: User[]): User[] {
    if (!users?.length) {
      return [];
    }
    return users.filter(user => user.organizationRoleId !== UserRoleEnum.Owner);
  }
}
