export interface PaginationState {
  totalCount: number;
  pageNumber: number;
  pageSize: number;
}

export const DEFAULT_PAGINATION_STATE = {
  totalCount: 0,
  pageNumber: 1,
  pageSize: 10,
};

export const DEFAULT_PAGINATION_STATE_LIBRARY = {
  totalCount: 0,
  pageNumber: 1,
  pageSize: 200,
};
