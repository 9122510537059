import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { AuthenticationService } from '@core/services';
import { WorkspaceResponse, WorkspaceRole } from '@core/models/response';
import { UserRoleEnum } from '../consts/user-role.enum';
import { WorkspaceAction } from '@app/core/models/workspace-action.enum';
import { ActionToRolesMap } from '../consts/action-to-roles.const';
import { TranslateService } from '@ngx-translate/core';
import { WorkspaceRoleToLabel } from '../consts/workspace-role-to-label.const';

@Directive({
  selector: '[workspaceRequiredRoleIds]',
})
export class WorkspaceRequiredRolesDirective implements OnChanges {
  @Input() actionName: WorkspaceAction;
  @Input() workspace: WorkspaceResponse;

  constructor(
    private authService: AuthenticationService,
    private elRef: ElementRef,
    private renderer: Renderer2,
    private translateService: TranslateService
  ) {
    this.validateAccess();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.validateAccess();
  }

  private validateAccess(): void {
    const roles = ActionToRolesMap[this.actionName];
    if (!roles || !this.workspace) {
      return;
    }
    const currentUser = this.authService.userSubject.getValue();
    const workspaceRoleId = this.workspace?.users?.find((u: any) => u.userId === currentUser.userId)?.workspaceRoleId;

    if (!roles.find((r: any) => r === workspaceRoleId)) {
      this.renderer.setAttribute(this.elRef.nativeElement, 'disabled', 'true');
      this.renderer.addClass(this.elRef.nativeElement, 'disabled-by-role');
      this.renderer.setAttribute(this.elRef.nativeElement, 'title', this.tooltipMessage);
    } else {
      this.renderer.removeAttribute(this.elRef.nativeElement, 'disabled');
      this.renderer.removeClass(this.elRef.nativeElement, 'disabled-by-role');
      this.renderer.removeAttribute(this.elRef.nativeElement, 'title');
    }
    if (currentUser.organizationRoleId == UserRoleEnum.Owner || currentUser.organizationRoleId == UserRoleEnum.Admin) {
      this.renderer.removeAttribute(this.elRef.nativeElement, 'disabled');
      this.renderer.removeClass(this.elRef.nativeElement, 'disabled-by-role');
      this.renderer.removeAttribute(this.elRef.nativeElement, 'title');
    }
  }

  private get tooltipMessage(): string {
    const roles = ActionToRolesMap[this.actionName];
    return (
      this.translateService.instant('app_labels.available_for') +
      ': ' +
      roles.map(role => this.translateService.instant(WorkspaceRoleToLabel[role])).join(', ')
    );
  }
}
