import { DataspaceRole } from '@core/models/response';

export class CreateDataspaceRequest {
  dataspaceName: string;
  dataspaceDescription?: string;
}
export class UpdateDataspaceRequest {
  dataspaceName?: string;
  dataspaceDescription?: string;
  dataspaceId: string;
  isActive?: boolean;
}

export class CreateDataspaceUser {
  dataspaceId: string;
  userId: string;
  dataspaceRoleId: DataspaceRole;
}

export class AttachPromptToDataspaceRequest {
  dataspaceId: string;
  promptId: string;
  promptVersionId: string;
}
