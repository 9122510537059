import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { PageActivatorService } from '@app/core/guards/page-activator.service';
import { UserRoleEnum } from '@shared/consts/user-role.enum';

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'tools',
  },
  {
    path: 'home',
    loadChildren: () => import('./home/home.module').then(m => m.HomeModule),
  },
  {
    path: 'tools',
    loadChildren: () => import('./tools/tools.module').then(m => m.ToolsModule),
  },
  {
    path: 'templates',
    loadChildren: () => import('./templates/templates.module').then(m => m.TemplatesModule),
  },
  {
    path: 'prompts',
    loadChildren: () => import('./prompts/prompts.module').then(m => m.PromptsModule),
  },
  {
    path: 'fine-tuning',
    loadChildren: () => import('./fine-tuning/fine-tuning.module').then(m => m.FineTuningModule),
  },
  {
    path: 'audit-log',
    loadChildren: () => import('./audit-log/audit-log.module').then(m => m.AuditLogModule),
  },
  {
    path: 'feedback-log',
    loadChildren: () => import('./feedback-log/feedback-log.module').then(m => m.FeedbackLogModule),
  },
  {
    path: 'workspaces',
    loadChildren: () => import('./workspaces/workspaces.module').then(m => m.WorkspacesModule),
  },
  {
    path: 'dataspaces',
    loadChildren: () => import('./dataspaces/dataspaces.module').then(m => m.DataspacesModule),
  },
  {
    path: 'usage-reports',
    loadChildren: () => import('./usage-reports/usage-reports.module').then(m => m.UsageReportsModule),
  },
  {
    path: 'feedback-reports',
    loadChildren: () => import('./feedback-reports/feedback-reports.module').then(m => m.FeedbackReportsModule),
  },
  {
    path: 'settings',
    loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
    canActivate: [PageActivatorService],
  },
  {
    path: 'org-settings',
    loadChildren: () => import('./org-settings/org-settings.module').then(m => m.OrgSettingsModule),
  },
  {
    path: 'provider-keys',
    loadChildren: () => import('./provider-keys/provider-keys.module').then(m => m.ProviderKeysModule),
    canActivate: [PageActivatorService],
    data: { roles: [UserRoleEnum.Owner] },
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class PagesRoutingModule {}
