import { Injectable } from '@angular/core';
import { RestBaseService } from '../rest-base.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, defer, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { AuthenticationService } from '@core-services/authentication.service';
import { Data } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class DataService extends RestBaseService {
  data$ = new BehaviorSubject<Data | null>(null);
  constructor(public http: HttpClient, private authService: AuthenticationService) {
    super(http);
    var todayDate = new Date();
  }
  peek() {
    return this.data$.getValue();
  }
  watch() {
    return this.data$;
  }
  poke(data: Data) {
    this.data$.next(data);
  }

  deleteAllData(reason: string, reasonText: string, contactMe: boolean): Observable<boolean> {
    return this.http.delete<boolean>(`${this.apiUrl}/api/data?reason=${reason}&reasonText=${reasonText}&contactMe=${contactMe}`).pipe(
      map(results => {
        return results;
      })
    );
  }
}
