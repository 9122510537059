import { Injectable } from '@angular/core';
import { RestBaseService } from '../rest-base.service';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { MetaData } from '@app/core/models/meta-data';

@Injectable({
  providedIn: 'root',
})
export class MetaDataService extends RestBaseService {
  metaData$ = new BehaviorSubject<MetaData | null>(null);
  constructor(public http: HttpClient) {
    super(http);
  }
  peek() {
    return this.metaData$.getValue();
  }
  watch() {
    return this.metaData$;
  }
  poke(metaData: MetaData) {
    this.metaData$.next(metaData);
  }
  get(): Observable<MetaData> {
    return this.http.get<MetaData>(`${this.apiUrl}/api/meta-data`).pipe(
      map(results => {
        this.poke(results);
        return results;
      })
    );
  }
}
