import { Pipe, PipeTransform } from '@angular/core';
import { WorkspaceRole, WorkspaceUserResponse } from '@app/core/models/response';

@Pipe({
  name: 'filterOutWorkspaceOwner',
})
export class FilterOutWorkspaceOwnerPipe implements PipeTransform {
  transform(users: WorkspaceUserResponse[]): WorkspaceUserResponse[] {
    if (!users?.length) {
      return [];
    }
    return users.filter(user => user.workspaceRoleId !== WorkspaceRole.Owner);
  }
}
