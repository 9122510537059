import { Component, EventEmitter, Input, Output } from '@angular/core';
import { ImagesService } from '@core/services/images.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { finalize } from 'rxjs/operators';

@UntilDestroy()
@Component({
  selector: 'app-upload-image',
  templateUrl: './upload-image.component.html',
  styleUrls: ['./upload-image.component.scss'],
})
export class UploadImageComponent {
  @Input() set loaddedImage(url: string | null) {
    this.loadedImagePreview = url;
  }
  @Input() title: string;

  @Output() imageUrlReceived = new EventEmitter<string>();
  @Output() imageIsLoading = new EventEmitter<boolean>();

  selectedFiles?: FileList;
  currentFile?: File;
  preview: string;
  loadedImagePreview: string;
  constructor(private imagesService: ImagesService) {}

  selectFile(event: any): void {
    this.selectedFiles = event.target.files;

    if (this.selectedFiles) {
      const file: File | null = this.selectedFiles.item(0);

      if (file) {
        this.preview = '';
        this.currentFile = file;
        this.upload();

        const reader = new FileReader();

        reader.onload = (e: any) => {
          this.preview = e.target.result;
        };

        reader.readAsDataURL(this.currentFile);
      }
    }
  }
  upload() {
    if (!this.currentFile) {
      return;
    }
    this.imageIsLoading.emit(true);

    const formData = new FormData();
    formData.append('image', this.currentFile);
    this.imagesService
      .post(formData)
      .pipe(
        untilDestroyed(this),
        finalize(() => this.imageIsLoading.emit(false))
      )
      .subscribe(res => {
        const resArr: string[] = res.Result.split('/');
        const imageName = resArr?.length ? resArr[resArr.length - 1] : res.Result;
        this.imageUrlReceived.emit(imageName);
      });
  }
}
