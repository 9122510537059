import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';

@Component({
  selector: 'app-curl-request',
  templateUrl: './curl-request.component.html',
  styleUrls: ['./curl-request.component.scss'],
})
export class CurlRequestComponent implements OnInit, OnChanges {
  @Input() apiUrl = 'https://api.hyperleap.ai/app/conversations';
  @Input() authToken = 'YOUR_DEVELOPER_API_KEY';
  @Input() jsonData: {} = {
    prompt: 'This is a test',
  };
  markDownData = '';

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.apiUrl || changes.authToken || changes.jsonData) {
      this.generateMarkDownData();
    }
  }

  ngOnInit(): void {
    this.generateMarkDownData();
  }

  private generateMarkDownData(): void {
    this.markDownData =
      '```bash\n' +
      "curl -X POST '" +
      this.apiUrl +
      "' \\\n" +
      "-H 'Content-Type: application/json' \\\n" +
      "-H 'x-hl-api-key " +
      this.authToken +
      "' \\\n" +
      "-d '" +
      JSON.stringify(this.jsonData, null, 4) +
      "'\n" +
      '```\n';
  }
}
