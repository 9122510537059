import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'getUnixTimeDifference',
})
export class GetUnixTimeDifferencePipe implements PipeTransform {
  transform(startTime: number, endTime: number): string {
    if (!startTime || !endTime) {
      return null;
    }
    const convertedStartTime = new Date(startTime * 1000);
    const convertedEndTime = new Date(endTime * 1000);
    const diff = convertedEndTime.getTime() - convertedStartTime.getTime();
    const minutes = Math.floor(diff / (1000 * 60));
    const seconds = Math.floor((diff % (1000 * 60)) / 1000);

    return `${minutes}m${seconds}s`;
  }
}
