export interface IConfiguration {
  appOffline: boolean;
  forceAppUpdate: boolean;
  appVersion: string;
  appStoreLink: string;
  privacyLink: string;
  termsLink: string;
  faqLink: string;
  contactEmail: string;
  contactSubject: string;
  feedbackEmail: string;
  feedbackSubject: string;
}

export enum AlertType {
  Toast = 'toast',
}

export class Dictionary<T> {
  key: string;
  value: T;
  public constructor(init?: Partial<Dictionary<T>>) {
    Object.assign(this, init);
  }
}

export enum APP_EVENTS {
  SendOTP = 'send_otp',
  Login = 'login',
  AskToPurchase = 'ask_to_purchase',
  OpenTerms = 'open_terms',
  OpenPrivacy = 'open_privacy',
  OpenFAQ = 'open_faq',
  FeedbackRequest = 'feedback_request',
  ContactRequest = 'contact_request',
  DisplayPreferenceChanged = 'display_preference_changed',
  DeleteAccountIntent = 'delete_account_intent',
  DeleteAccountConfirmed = 'delete_account_confirmed',
  DeleteAccountCancelled = 'delete_account_cancelled',
  DeleteAccountConfirmedOnServer = 'delete_account_confirmed_server',
  AdvancedOptions = 'advanced_options',
}
export enum OpenAiModels {
  TextDavinci003 = 'text-davinci-003',
  TextCurie001 = 'text-curie-001',
  TextBabbage001 = 'text-babbage-001',
  TextAda001 = 'text-ada-001',
  Gpt35Turbo16k = 'gpt-3.5-turbo-16k',
  Gpt35Turbo = 'gpt-3.5-turbo',
  Gpt4 = 'gpt-4',
  TextDavinciEdit001 = 'text-davinci-edit-001',
}
export enum OpenAiPromptType {
  None = 0,
  Text = 1,
  Chat = 2,
  Edit = 3,
}
