export enum UserRoleEnum {
  None = 0,
  Owner = 1,
  Admin = 2,
  Member = 3,
}

export const UserRoleNameMap = {
  [UserRoleEnum.None]: 'Regular',
  [UserRoleEnum.Owner]: 'Owner',
  [UserRoleEnum.Admin]: 'Admin',
  [UserRoleEnum.Member]: 'Member',
};




export enum RequiredFeature {
  Dataspaces = 'dataspaces',
  UsageReports = "UsageReports",
  FeedbackReports ="FeedbackReports"
}