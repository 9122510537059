<form class="row row-cols-sm-auto range-datepicker-form">
  <div class="col-12">
    <p class="form-filter-label" *ngIf="showLabels">{{ 'app_forms.labels.list.start_date' | translate }} *</p>
    <div class="dp-hidden position-absolute">
      <div class="input-group">
        <input
          name="datepicker"
          class="form-control h-100"
          ngbDatepicker
          #datepicker="ngbDatepicker"
          [autoClose]="'outside'"
          (dateSelect)="onDateSelection($event)"
          [displayMonths]="2"
          [dayTemplate]="t"
          [footerTemplate]="timePicker"
          outsideDays="hidden"
          [startDate]="fromDate!"
          tabindex="-1" />
        <ng-template #t let-date let-focused="focused">
          <span
            class="custom-day"
            [class.focused]="focused"
            [class.range]="isRange(date)"
            [class.faded]="isHovered(date) || isInside(date)"
            (mouseenter)="hoveredDate = date"
            (mouseleave)="hoveredDate = null">
            {{ date.day }}
          </span>
        </ng-template>

        <ng-template #timePicker>
          <div *ngIf="showTimePicker" class="time-picker__wrapper d-flex align-items-center justify-content-between">
            <ngb-timepicker [(ngModel)]="fromTime" name="fromTime" (ngModelChange)="timeChange()"></ngb-timepicker>
            —
            <ngb-timepicker [(ngModel)]="toTime" name="toTime" (ngModelChange)="timeChange()"></ngb-timepicker>
          </div>
        </ng-template>
      </div>
    </div>
    <div class="input-group">
      <input
        #dpFromDate
        class="form-control h-100"
        placeholder="yyyy-mm-dd hh:mm:ss"
        name="dpFromDate"
        [value]="formatter.format(fromDate) + ' ' + (fromTime | formatTimePickerData : showTimePicker)"
        (input)="fromDate = validateInput(fromDate, dpFromDate.value)" />
      <button class="btn btn-outline h-100" (click)="datepicker.toggle()" type="button">
        <i-feather name="calendar"></i-feather>
      </button>
    </div>
  </div>
  <div class="col-12">
    <p class="form-filter-label" *ngIf="showLabels">{{ 'app_forms.labels.list.end_date' | translate }} *</p>
    <div class="input-group">
      <input
        #dpToDate
        class="form-control h-100"
        placeholder="yyyy-mm-dd hh:mm:ss"
        name="dpToDate"
        [value]="formatter.format(toDate) + ' ' + (toTime | formatTimePickerData : showTimePicker)"
        (input)="toDate = validateInput(toDate, dpToDate.value)" />
      <button class="btn btn-outline h-100" (click)="datepicker.toggle()" type="button">
        <i-feather name="calendar"></i-feather>
      </button>
    </div>
  </div>
</form>
