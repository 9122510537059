import { User, UserResponse } from '../auth';
import { PersonaResponse } from './avatar';
import { PromptResponse } from './prompts';
import { AttachPromptToWorkspaceRequest, CreateWorkspaceUser } from '@core/models/request';

export class WorkspaceResponse {
  workspaceId: string;
  workspaceName: string;
  workspaceDescription: string;
  prompts: WorkspacePromptResponse[];
  personas: WorkspacePersonaResponse[];
  users: WorkspaceUserResponse[];
  promptCount: number;
  personaCount: number;
  userCount: number;
  isActive: boolean;
}
export class WorkspacePromptResponse {
  workspacePromptId: string;
  workspaceId: string;
  promptId: string;
  prompt: PromptResponse;
  isChattable: boolean;
}
export class WorkspacePersonaResponse {
  workspacePersonaId: string;
  workspaceId: string;
  personaId: string;
  persona: PersonaResponse;
  isChattable: boolean;
}
export class WorkspaceUserResponse {
  workspaceUserId: string;
  workspaceId: string;
  userId: string;
  workspaceRoleId: WorkspaceRole;
  user: UserResponse;
}

export class CreateWorkspaceUserResponse extends CreateWorkspaceUser {
  user: User;
}

export class AttachPromptToWorkspaceResponse extends AttachPromptToWorkspaceRequest {
  prompt: PromptResponse;
}

export class WorkspaceCounts {
  activeWorkspaceCount: number;
  archivedWorkspaceCount: number;
}

export enum WorkspaceRole {
  None = 0,
  Owner = 1,
  Admin = 2,
  Engineer = 3,
  Reader = 4,
  Guest = 5,
}

export const WorkspaceRoleNameMap = {
  [WorkspaceRole.None]: 'None',
  [WorkspaceRole.Owner]: 'Owner',
  [WorkspaceRole.Admin]: 'Admin',
  [WorkspaceRole.Engineer]: 'Engineer',
  [WorkspaceRole.Reader]: 'Reader',
  [WorkspaceRole.Guest]: 'Guest',
};
