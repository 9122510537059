<div class="row">
  <div class="col-3 d-flex align-items-center justify-content-end">
    <label [for]="'eyeControl-' + controlName" class="form-label text-end m-0 me-2">{{ label }}</label>
  </div>

  <div class="col-9 d-flex align-items-center">
    <i
      *ngIf="!isVisible"
      role="button"
      class="fa fa-eye-slash"
      appTooltip
      [title]="'app_actions.list.show_value' | translate"
      (click)="isVisible = true"></i>
    <i
      *ngIf="isVisible"
      role="button"
      class="fa fa-eye"
      appTooltip
      [title]="'app_actions.list.hide_value' | translate"
      (click)="isVisible = false"></i>
    <input
      *ngIf="isVisible"
      [id]="'eyeControl-' + controlName"
      class="form-control ms-2"
      autofocus
      placeholder=""
      type="text"
      [(ngModel)]="value"
      (ngModelChange)="valueChange()"
      [disabled]="disabled" />
  </div>
</div>
