export enum WorkspaceAction {
  RunPrompt = 'RunPrompt',
  ReadPromptDetails = 'ReadPromptDetails',
  AddPromptsVersions = 'AddPromptsVersions',
  EnableDisableAPI = 'EnableDisableAPI',
  EditExistingVersions = 'EditExistingVersions',
  DeletePrompt = 'DeletePrompt',
  WorkspaceManagement = 'WorkspaceManagement',
  // Personas
  OpenPersonaInPlayground = 'OpenInPlayground',
  StartPersonaChat = 'StartPersonaChat',
  EditPersona = 'EditPersona',
  ActivateDeactivatePersona = 'ActivateDeactivatePersona',
  PersonaApiIntegration = 'PersonaApiIntegration',
}

export enum DataspaceAction {
  DataspaceManagement = 'DataspaceManagement',
}

export enum DataspaceUploadOptions {
  Documents = 'Documents',
  Images = 'Images',
  Websites = 'Websites',
  Strings = 'Strings',
}

export enum DataspaceIds {
  DocumentId = "dataspaceDocumentId",
  ImageId = "dataspaceImageId",
  WebsiteId = "dataspaceWebsiteId",
  StringId = "dataspaceStringId"
}