import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Pipe({
  name: 'sanitizeString',
})
export class SanitizeStringPipe implements PipeTransform {
  constructor(private sanitizer: DomSanitizer) {}

  transform(html: string): string {
    // return this.sanitizer.bypassSecurityTrustHtml(html).toString();
    return this.sanitizer.sanitize(SecurityContext.HTML, this.sanitizer.bypassSecurityTrustHtml(html));
  }
}
