import { Component, TemplateRef } from '@angular/core';
import { ToastInfo, ToastService, ToastType } from '@shared/services/toast.service';

@Component({
  selector: 'app-toast-container',
  templateUrl: './toast-container.component.html',
  styleUrls: ['./toast-container.component.scss'],
  host: { '[class.ngb-toasts]': 'true' },
})
export class ToastContainerComponent {
  constructor(public toastService: ToastService) {}

  isTemplate(toast: ToastInfo) {
    return toast.textOrTpl instanceof TemplateRef;
  }

  getTemplate(toast: ToastInfo) {
    return this.isTemplate(toast) ? (toast.textOrTpl as TemplateRef<any>) : null;
  }

  getLineClass(toast: ToastInfo) {
    switch (toast.type) {
      case ToastType.Success:
        return 'bg-primary';
      case ToastType.Warning:
        return 'bg-warning';
      case ToastType.Danger:
        return 'bg-danger';
      default:
        return 'bg-primary';
    }
  }
}
