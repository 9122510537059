import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AppConfigService } from '@core/services/app-config.service';
import { EMPTY } from 'rxjs';
import { catchError, exhaustMap, map } from 'rxjs/operators';
import { getAppConfigs, setAppConfigs } from '@app/store/app-configs/app-configs.actions';
import { AppConfigModel } from '@core/models/app-config.model';

@Injectable()
export class AppConfigsEffects {
  loadAppConfigs$ = createEffect(() =>
    this.actions$.pipe(
      ofType(getAppConfigs),
      exhaustMap(() => {
        return this.appConfigService.get().pipe(
          map((configs: AppConfigModel) => {
            return setAppConfigs({ configs });
          }),
          catchError(() => {
            return EMPTY;
          })
        );
      })
    )
  );
  constructor(private actions$: Actions, private appConfigService: AppConfigService) {}
}
