import { Component } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'app-input-chips',
  templateUrl: './input-chips.component.html',
  styleUrls: ['./input-chips.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: InputChipsComponent,
    },
  ],
})
export class InputChipsComponent implements ControlValueAccessor {
  chipsString: string = '';
  chips: {
    display: string;
    value: string;
  }[] = [];
  touched = false;
  disabled = false;
  isTagInputFocused = false;
  tagInputText = '';

  onChange = (chipsString: string) => {};

  onTouched = () => {};

  writeValue(chipsString: string) {
    this.chipsString = chipsString;
    const chips = chipsString
      .trim()
      .split(',')
      .filter(chip => chip);
    chips.forEach(chip => {
      this.chips.push({
        display: chip.trim(),
        value: chip.trim(),
      });
    });
  }

  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  registerOnTouched(onTouched: any) {
    this.onTouched = onTouched;
  }

  markAsTouched() {
    if (!this.touched) {
      this.onTouched();
      this.touched = true;
    }
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  valueChange() {
    this.onChange(this.getChipsString());
  }

  getChipsString(): string {
    return this.chips.map(chip => chip.value).join(',');
  }

  onTagInputFocus() {
    this.isTagInputFocused = true;
  }

  onTagInputBlur() {
    if (!this.chips?.length) {
      this.isTagInputFocused = false;
    }
  }
}
