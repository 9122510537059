<div class="card">
  <div class="card-body">
    <div class="text-center">
      <div class="title">
        <h6>{{ title ? title : 'Upload image' }}</h6>
        <hr />
      </div>
      <div
        class="drop position-relative d-flex align-items-center justify-content-center rounded"
        [ngClass]="{ 'drop-border': !preview && !loadedImagePreview }"
        [ngStyle]="{ 'background-image': preview }">
        <img
          *ngIf="loadedImagePreview && !preview"
          class="preview rounded cover-image w-100"
          [src]="loadedImagePreview | secure | async"
          alt="" />
        <img *ngIf="preview" class="preview rounded cover-image w-100" [src]="preview" alt="" />

        <div>
          <i *ngIf="!preview && !loadedImagePreview" class="fa fa-cloud-download-alt"></i>
          <input type="file" class="upload-input position-absolute" (change)="selectFile($event)" />
        </div>
      </div>
    </div>
  </div>
</div>
