import { TranslateModule } from '@ngx-translate/core';
import { CommonModule, DatePipe } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { SwitchComponent } from './components/switch/switch.component';
import { PendingChangesGuard } from '../core/guards/pending-changes.guard';
import { PagetitleComponent } from './components/pagetitle/pagetitle.component';
import { ConfirmationModalComponent } from './components/confirmation-modal/confirmation-modal.component';
import { allIcons } from 'angular-feather/icons';
import { FeatherModule } from 'angular-feather';
import { MarkdownResultComponent } from './components/markdown-result/markdown-result.component';
import { MarkdownModule, MarkedOptions } from 'ngx-markdown';
import { HighlightModule } from 'ngx-highlightjs';
import { MarkdownPrerenderedComponent } from './components/markdown-prerendered/markdown-prerendered.component';
import { CurrencyFormatPipe } from '@app/core/pipes/currency';
import { DateFormatPipe } from '@app/core/pipes/date';
import { HumanizePipe } from '@app/core/pipes/humanize';
import { SearchPipe } from '@app/core/pipes/search';
import { NavigativeBreadcrumbsComponent } from './components/navigative-breadcrumbs/navigative-breadcrumbs.component';
import {
  NgbAlert,
  NgbDatepicker,
  NgbDropdown,
  NgbDropdownMenu,
  NgbDropdownToggle,
  NgbInputDatepicker,
  NgbNav,
  NgbNavItem,
  NgbNavLink,
  NgbPagination,
  NgbTimepickerModule,
  NgbToast,
} from '@ng-bootstrap/ng-bootstrap';
import { ToastContainerComponent } from './components/toast-container/toast-container.component';
import { InputChipsComponent } from './components/input-chips/input-chips.component';
import { ChipComponent } from './components/chip/chip.component';
import { TagInputModule } from 'ngx-chips';
import { ScrollableItemDirective } from '@shared/directives/scrolable-item.directive';
import { TimePipe } from './pipes/time.pipe';
import { TabsComponent } from './components/tabs/tabs.component';
import { SpinnerComponent } from './components/spinner/spinner.component';
import { NoDataComponent } from './components/no-data/no-data.component';
import { PromptResultComponent } from './components/prompt-result/prompt-result.component';
import { RequiredRolesDirective } from '@app/shared/directives/required-roles.directive';
import { PromptsTableComponent } from './components/prompts-table/prompts-table.component';
import { TooltipDirective } from '@shared/directives/tooltip.directive';
import { GoToWorkspaceComponent } from './components/go-to-workspace/go-to-workspace.component';
import { FolderComponent } from './components/folder/folder.component';
import { CurlRequestComponent } from '@shared/components/curl-request/curl-request.component';
import { UploadImageComponent } from './components/upload-image/upload-image.component';
import { SecurePipe } from '@shared/pipes/secure.pipe';
import { SmallImageResolutionPipe } from '@shared/pipes/small-image-resolution.pipe';
import { OtpWarningComponent } from './components/otp-warning/otp-warning.component';
import { EyeControlComponent } from './components/eye-control/eye-control.component';
import { FullScreenSpinnerComponent } from './components/full-screen-spinner/full-screen-spinner.component';
import { FilterEmptyWorkspacesPipe } from './pipes/filter-empty-workspaces.pipe';
import { IsDateExpiredPipe } from './pipes/is-date-expired.pipe';
import { CopyToClipboardDirective } from './directives/copy-to-clipboard.directive';
import { RangeDatepickerComponent } from './components/range-datepicker/range-datepicker.component';
import { GetObjectFieldPipe } from './pipes/get-object-field.pipe';
import { MarkdownCodeComponent } from './components/markdown-code/markdown-code.component';
import { GetIdFirstPartPipe } from './pipes/get-id-first-part.pipe';
import { FormatTimePickerDataPipe } from './pipes/format-time-picker-data.pipe';
import { GetUserRolePipe } from './pipes/get-user-role.pipe';
import { ConvertUnixToTimePipe } from './pipes/convert-unix-to-time.pipe';
import { GetTimeDifferencePipe } from './pipes/get-time-difference.pipe';
import { GetUnixTimeDifferencePipe } from './pipes/get-unix-time-difference.pipe';

import hljs from 'highlight.js/lib/core';
import { SanitizePipe } from './pipes/sanitize.pipe';
import { SanitizeStringPipe } from './pipes/sanitize-string.pipe';
import { WorkspaceRequiredRolesDirective } from './directives/required-workspace-roles.directive';
import { FilterOutWorkspaceOwnerPipe } from './pipes/filter-out-workspace-owner.pipe';
import { FilterOutOrganizationOwnerPipe } from './pipes/filter-out-organization-owner.pipe';
import { IsValidWorkspaceRolePipe } from './pipes/is-valid-workspace-role.pipe';
import { PreserveMarkdownLineBreaksPipe } from './pipes/preserve-markdown-line-breaks.pipe';
import { IsValidUserRolePipe } from './pipes/is-valid-user-role.pipe';
import { FilterEmptyDataspacesPipe } from './pipes/filter-empty-dataspaces.pipe';
import { RequiredFeatureDirective } from './directives/required-feature.directive';

const exportComponents = [
  SwitchComponent,
  PagetitleComponent,
  MarkdownResultComponent,
  MarkdownPrerenderedComponent,
  ConfirmationModalComponent,
  NavigativeBreadcrumbsComponent,
  ToastContainerComponent,
  InputChipsComponent,
  TabsComponent,
  SpinnerComponent,
  NoDataComponent,
  PromptResultComponent,
  PromptsTableComponent,
  FolderComponent,
  CurlRequestComponent,
  UploadImageComponent,
  OtpWarningComponent,
  EyeControlComponent,
  FullScreenSpinnerComponent,
  RangeDatepickerComponent,
  MarkdownCodeComponent,
];
const pipes = [
  SearchPipe,
  DateFormatPipe,
  CurrencyFormatPipe,
  HumanizePipe,
  TimePipe,
  SecurePipe,
  SmallImageResolutionPipe,
  FilterEmptyWorkspacesPipe,
  FilterEmptyDataspacesPipe,
  IsDateExpiredPipe,
  GetObjectFieldPipe,
  GetIdFirstPartPipe,
  FormatTimePickerDataPipe,
  GetUserRolePipe,
  ConvertUnixToTimePipe,
  GetTimeDifferencePipe,
  GetUnixTimeDifferencePipe,
  SanitizePipe,
  SanitizeStringPipe,
  FilterOutWorkspaceOwnerPipe,
  FilterOutOrganizationOwnerPipe,
  IsValidWorkspaceRolePipe,
  PreserveMarkdownLineBreaksPipe,
  IsValidUserRolePipe,
];
const directives = [
  ScrollableItemDirective,
  RequiredRolesDirective,
  RequiredFeatureDirective,
  WorkspaceRequiredRolesDirective,
  TooltipDirective,
  CopyToClipboardDirective,
];

@NgModule({
  declarations: [...exportComponents, ...pipes, ...directives, ChipComponent, GoToWorkspaceComponent],
  imports: [
    CommonModule,
    FormsModule,
    MarkdownModule.forRoot({
      markedOptions: {
        provide: MarkedOptions,
        useValue: {
          highlight: (code: string, options: any) => {
            if (options.lang && hljs.getLanguage(options.lang)) {
              return hljs.highlight(code, options).value;
            }
            return hljs.highlightAuto(code).value;
          },
        },
      },
    }),
    HighlightModule,
    FlexLayoutModule,
    FeatherModule.pick(allIcons),
    NgbAlert,
    NgbToast,
    ReactiveFormsModule,
    TagInputModule,
    NgbNav,
    NgbNavItem,
    NgbNavLink,
    NgbPagination,
    NgbDropdown,
    NgbDropdownToggle,
    NgbDropdownMenu,
    TranslateModule,
    NgbDatepicker,
    NgbInputDatepicker,
    NgbTimepickerModule,
  ],
  exports: [...exportComponents, ...pipes, ...directives],
  providers: [PendingChangesGuard, DatePipe],
})
export class SharedModule {}
