import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot } from '@angular/router';

import { Injectable } from '@angular/core';
import { AuthenticationService } from '@core-services/authentication.service';

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
  constructor(private _router: Router, private _authservice: AuthenticationService) {}
  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    const user = this._authservice.userValue;
    if (user && user.jwtToken) {
      if (route.data.roles && route.data.roles.indexOf(user.role) == -1) {
        this._authservice.logout();
        this._router.navigate(['/account/login'], {
          queryParams: { returnUrl: state.url },
        });
        return false;
      } else {
        return true;
      }
    } else {
      // not logged in so redirect to login page with the return url
      this._router.navigate(['/account/login'], { queryParams: { returnUrl: state.url } });
      return false;
    }
  }
}
