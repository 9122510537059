<div class="table-responsive datatables">
  <table class="table align-middle table-nowrap table-check table-hover mb-0">
    <thead>
      <tr>
        <!-- <th class="no-sorting-column actions-column" scope="col">{{ 'app_labels.actions' | translate }}</th> -->

        <th class="no-sorting-column small-column" scope="col">{{ 'app_pages.tools.list.prompt_id' | translate }}</th>

        <th
          (click)="sort('promptName')"
          [ngClass]="{
            'no-sorting-column': this.disableSorting,
            asc: sortOptions?.sortAscending && sortOptions?.sortBy === promptSortByOption.PromptName,
            desc: !sortOptions?.sortAscending && sortOptions?.sortBy === promptSortByOption.PromptName
          }"
          class="name-column">
          {{ 'app_pages.prompts.list.prompt_name_title' | translate }}
        </th>
        <th class="no-sorting-column" scope="col">{{ 'app_pages.prompts.list.prompt_text_title_default' | translate }}</th>
        <th *ngIf="isWorkspacePrompts">API</th>
        <th
          class="small-column"
          (click)="sort('lastModifiedAt')"
          [ngClass]="{
            'no-sorting-column': this.disableSorting,
            asc: sortOptions?.sortAscending && sortOptions?.sortBy === promptSortByOption.LastModifiedAt,
            desc: !sortOptions?.sortAscending && sortOptions?.sortBy === promptSortByOption.LastModifiedAt
          }">
          {{ 'app_labels.last_modified_at' | translate }}
        </th>
      </tr>
    </thead>
    <tbody>
      <tr *ngFor="let prompt of prompts; let i = index" (click)="go(prompt)" class="pointer">
        <td>{{ prompt.promptId.split('-')[0] }}</td>
        <td>{{ prompt.promptName }}</td>
        <td>{{ prompt.promptText }}</td>
        <td *ngIf="isWorkspacePrompts">
          <div *ngIf="prompt.isApiEnabled; else apiDisabledBadge" class="badge badge-soft-success">
            {{ 'app_labels.enabled' | translate }}
          </div>
          <ng-template #apiDisabledBadge>
            <div class="badge badge-soft-warning">{{ 'app_labels.disabled' | translate }}</div>
          </ng-template>
        </td>
        <td>{{ prompt.lastModifiedAt + 'Z' | date : 'dd-MMM-YYYY HH:mm:ss' }}</td>
      </tr>
    </tbody>
  </table>
</div>

<tr *ngIf="isLoading" class="row justify-content-center spinner">
  <app-spinner></app-spinner>
</tr>

<div class="mt-3" *ngIf="prompts?.length >= paginationState.pageSize || paginationState.pageNumber > 1">
  <div class="text-md-right float-md-end pagination-rounded">
    <ngb-pagination
      class="row justify-content-end"
      [collectionSize]="paginationState.totalCount"
      [page]="paginationState.pageNumber"
      (pageChange)="pageChanged.emit($event)"
      [pageSize]="paginationState.pageSize">
    </ngb-pagination>
  </div>
</div>
