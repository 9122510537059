import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  SimpleChanges
} from "@angular/core";
import { ReportsType } from "@app/core/models/reports-type.enum";
import { FormatTimePickerDataPipe } from "@app/shared/pipes/format-time-picker-data.pipe";
import { CONVERT_DATE_TO_STRING } from "@app/shared/utils/convert-date-to-string.util";
import {
  NgbCalendar,
  NgbDate,
  NgbDateParserFormatter
} from "@ng-bootstrap/ng-bootstrap";
import { NgbTime } from "@ng-bootstrap/ng-bootstrap/timepicker/ngb-time";

export interface RangeDatepickerOutput {
  from: string;
  to: string;
}

export interface TimePickerData {
  hour: number;
  minute: number;
}

@Component({
  selector: "app-range-datepicker",
  templateUrl: "./range-datepicker.component.html",
  styleUrls: ["./range-datepicker.component.scss"],
  providers: [FormatTimePickerDataPipe]
})
export class RangeDatepickerComponent implements OnInit {
  @Input() showLabels = false;
  @Input() showTimePicker = false;
  @Input() reportRange: string;
  @Output() dateChanged: EventEmitter<RangeDatepickerOutput> = new EventEmitter();

  hoveredDate: NgbDate | null = null;
  fromDate: NgbDate | null;
  fromTime: TimePickerData | null = { hour: 0, minute: 0 };
  toDate: NgbDate | null;
  toTime: TimePickerData | null = { hour: 0, minute: 0 };

  constructor(
    private calendar: NgbCalendar,
    public formatter: NgbDateParserFormatter,
    private formatTimePipe: FormatTimePickerDataPipe
  ) {
    this.fromDate = calendar.getPrev(calendar.getToday(), "m", 1);
    //this.toDate = calendar.getToday();
    this.toDate = calendar.getNext(calendar.getToday(), "d", 1);
  }

  ngOnInit(): void {
    this.emitDateChange();
  }

  onDateSelection(date: NgbDate) {
    if (!this.fromDate && !this.toDate) {
      this.fromDate = date;
    } else if (
      this.fromDate &&
      !this.toDate &&
      date &&
      date.after(this.fromDate)
    ) {
      this.toDate = date;
    } else {
      this.toDate = null;
      this.fromDate = date;
    }

    this.emitDateChange();
  }

  timeChange() {
    this.emitDateChange();
  }

  isHovered(date: NgbDate) {
    return (
      this.fromDate &&
      !this.toDate &&
      this.hoveredDate &&
      date.after(this.fromDate) &&
      date.before(this.hoveredDate)
    );
  }

  isInside(date: NgbDate) {
    return this.toDate && date.after(this.fromDate) && date.before(this.toDate);
  }

  isRange(date: NgbDate) {
    return (
      date.equals(this.fromDate) ||
      (this.toDate && date.equals(this.toDate)) ||
      this.isInside(date) ||
      this.isHovered(date)
    );
  }

  validateInput(currentValue: NgbDate | null, input: string): NgbDate | null {
    const parsed = this.formatter.parse(input);
    return parsed && this.calendar.isValid(NgbDate.from(parsed))
      ? NgbDate.from(parsed)
      : currentValue;
  }

  private emitDateChange(): void {
    const fromDate = CONVERT_DATE_TO_STRING(this.fromDate);
    const fromTime = this.formatTime(this.fromTime);
    const fromResult = fromDate ? `${fromDate} ${fromTime}` : null;

    const toDate = CONVERT_DATE_TO_STRING(this.toDate);
    const toTime = this.formatTime(this.toTime);
    const toResult = toDate ? `${toDate} ${toTime}` : null;

    this.dateChanged.emit({
      from: fromResult,
      to: toResult
    });
  }

  private formatTime(time: TimePickerData): string {
    return this.formatTimePipe.transform(time);
  }

}
