import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { RestBaseService } from '@core/rest-base.service';
import { AppConfigModel } from '@core/models/app-config.model';

@Injectable({
  providedIn: 'root',
})
export class AppConfigService extends RestBaseService {
  constructor(http: HttpClient) {
    super(http);
  }

  get() {
    return this.http.get<AppConfigModel>(`${this.apiUrl}/app/app-config`);
  }
}
