import { Directive, EventEmitter, HostListener, Input, Output } from '@angular/core';
import { ToastService, ToastType } from '../services/toast.service';
import { TranslateService } from '@ngx-translate/core';

@Directive({
  selector: '[appCopyToClipboard]',
})
export class CopyToClipboardDirective {
  @Input() public appCopyToClipboard: string;

  @Output() public copied = new EventEmitter<string>();

  constructor(private toastService: ToastService, private translateService: TranslateService) {}

  @HostListener('click', ['$event'])
  public onClick(event: MouseEvent): void {
    event.preventDefault();
    if (!this.appCopyToClipboard) return;

    let listener = (e: ClipboardEvent) => {
      let clipboard = e.clipboardData;
      clipboard.setData('text', this.appCopyToClipboard.toString());
      e.preventDefault();

      this.copied.emit(this.appCopyToClipboard);
    };

    document.addEventListener('copy', listener, false);
    document.execCommand('copy');
    document.removeEventListener('copy', listener, false);

    this.toastService.show(this.translateService.instant('app_actions.list.copied'), ToastType.Success);
  }
}
