import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'preserveMarkdownLineBreaks',
})
export class PreserveMarkdownLineBreaksPipe implements PipeTransform {
  transform(message: string): string {
    return message.replace(/\n/g, '</br>');
  }
}
