import { Injectable } from '@angular/core';
import { environment } from '@env';

declare const Stripe: stripe.StripeStatic;

@Injectable({
  providedIn: 'root',
})
export class StripeService {
  private stripe: stripe.Stripe;

  constructor() {}

  init() {
    if (this.stripe) {
      return;
    }
    this.stripe = Stripe(environment.stripe.publishableKey);
  }
}
