import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-folder',
  templateUrl: './folder.component.html',
  styleUrls: ['./folder.component.scss'],
})
export class FolderComponent {
  @Input() name = '';
  @Input() description = '';
  @Input() additionalInfo = '';
  @Input() status = '';
  @Input() statusColor = '';
  @Output() openClicked = new EventEmitter();
}
