import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { RestBaseService } from '@app/core/rest-base.service';
import { Observable, Subject, tap } from 'rxjs';
import { OrgConfigurationResponse } from '@core/models/response/org-configuration';
import { OrgConfigurationRequest } from '@core/models/request/org-configuration';

@Injectable({
  providedIn: 'root',
})
export class OrgConfigurationsService extends RestBaseService {
  providersChangeEvent$ = new Subject();

  constructor(http: HttpClient) {
    super(http);
  }

  post(request: OrgConfigurationRequest): Observable<OrgConfigurationResponse> {
    return this.http.post<OrgConfigurationResponse>(`${this.apiUrl}/manage/org-configurations`, request).pipe(
      tap(() => {
        this.providersChangeEvent$.next(true);
      })
    );
  }

  get(): Observable<OrgConfigurationResponse[]> {
    return this.http.get<OrgConfigurationResponse[]>(`${this.apiUrl}/manage/org-configurations`);
  }

  patch(request: OrgConfigurationRequest): Observable<OrgConfigurationResponse> {
    return this.http.patch<OrgConfigurationResponse>(`${this.apiUrl}/manage/org-configurations`, request).pipe(
      tap(() => {
        this.providersChangeEvent$.next(true);
      })
    );
  }

  delete(organizationConfigurationId: string): Observable<OrgConfigurationResponse> {
    return this.http.delete<OrgConfigurationResponse>(`${this.apiUrl}/manage/org-configurations/${organizationConfigurationId}`).pipe(
      tap(() => {
        this.providersChangeEvent$.next(true);
      })
    );
  }

  getKey(id: string): Observable<OrgConfigurationResponse> {
    return this.http.get<OrgConfigurationResponse>(`${this.apiUrl}/manage/org-configurations/key/${id}`);
  }

  getKeyByName(name: string): Observable<OrgConfigurationResponse> {
    return this.http.get<OrgConfigurationResponse>(`${this.apiUrl}/manage/org-configurations/key-name/${name}`);
  }
}
