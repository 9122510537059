import { AISystemNameResponse, UserMiniResponse } from './prompts';

export class PersonaResponse {
  personaId: string;
  personaName: string;
  personaDescription: string;
  personaSystemMessage: string;
  personaAssistantMessage: string;
  personaImageUrl: string;
  aiSystem: AISystemNameResponse;
  model: string;
  personaConfiguration: Record<string, any>;
  personaMetadata: Record<string, any>;
  isActive: boolean;
  ownerUser: UserMiniResponse;
  modifiedAt: Date;
  createdAt: Date;
  workspaceId: string;
  defaultVersionId: string;
  isApiEnabled: boolean;
  isChattable: boolean;
}

export interface ImageResponse {
  Result: string;
}

export class PersonasCounts {
  activePromptCount: number;
  archivedPromptCount: number;
}

export enum PersonaSortByOption {
  PersonaName = 'PersonaName',
  Model = 'Model',
  AiSystem = 'AiSystem',
  LastModifiedAt = 'LastModifiedAt',
  CreatedAt = 'CreatedAt',
}
