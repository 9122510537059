import { Component, EventEmitter, Input } from '@angular/core';

@Component({
  selector: 'app-prompt-result',
  templateUrl: './prompt-result.component.html',
  styleUrls: ['./prompt-result.component.scss'],
})
export class PromptResultComponent {
  @Input() finalContent: string;
  @Input() promptResult: any;
  @Input() timeDisplay: string;
  @Input() classToAttach?: string;

  isCopyClicked = false;

  isHoveredState = false;

  onMouseEnter(): void {
    this.isHoveredState = true;
  }

  onMouseLeave(): void {
    this.isHoveredState = false;
  }
  onCopyClicked(): void {
    this.isCopyClicked = true;
    setTimeout(() => {
      this.isCopyClicked = false;
    }, 2 * 1000);
  }

  @Input() onDataReceived: EventEmitter<string> = new EventEmitter<string>();
  @Input() onRequestReceived: EventEmitter<string> = new EventEmitter<string>();
  @Input() onFullDataReceived: EventEmitter<string> = new EventEmitter<string>();

  containsList() {
    // A list in markdown starts with either '-', '*', or a number followed by '.'.
    // We also check for optional whitespace after the list marker.
    const listRegex = /^(\s*(-|\*|\d+\.)\s+)/gm;
    return listRegex.test(this.finalContent);
  }
  containsCode() {
    // Inline code is enclosed between single backticks (`), and code blocks
    // are enclosed between triple backticks (```), optionally followed by a language.
    const inlineCodeRegex = /`[^`]+`/;
    const codeBlockRegex = /```[\w\s]*\n[\s\S]*?\n```/;
    return inlineCodeRegex.test(this.finalContent) || codeBlockRegex.test(this.finalContent);
  }
}
