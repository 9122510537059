import { Component, EventEmitter, Input, Output } from '@angular/core';
import { PromptResponse, PromptSortByOption, WorkspaceRole } from '@core/models/response';
import { ActivatedRoute, Router } from '@angular/router';
import { DEFAULT_PAGINATION_STATE, PaginationState } from '@core/models/pagination-state.model';
import { TableSortOptions } from '@core/models/table-sort-options.model';

export enum PromptTableAction {
  Edit,
  ArchiveActivate,
  CopyToWorkspace,
  API,
  LockUnclock,
}

@Component({
  selector: 'app-prompts-table',
  templateUrl: './prompts-table.component.html',
  styleUrls: ['./prompts-table.component.scss'],
})
export class PromptsTableComponent {
  @Input() prompts: PromptResponse[] = [];
  @Input() isDesc: boolean = false;
  @Input() paginationState: PaginationState = JSON.parse(JSON.stringify(DEFAULT_PAGINATION_STATE));
  @Input() isLoading: boolean = false;
  @Input() actionsList: PromptTableAction[] = [];
  @Input() sortOptions: TableSortOptions<PromptSortByOption>;
  @Input() disableSorting = false;
  @Input() isWorkspacePrompts = false;

  @Output() pageChanged = new EventEmitter();
  @Output() archiveClicked = new EventEmitter<PromptResponse>();
  @Output() activateClicked = new EventEmitter<PromptResponse>();
  @Output() editClicked = new EventEmitter<PromptResponse>();
  @Output() copyToWorkspaceClicked = new EventEmitter<PromptResponse>();
  @Output() private promptRowClicked = new EventEmitter<PromptResponse>();
  @Output() toogleApiClicked = new EventEmitter<PromptResponse>();
  @Output() sortOptionsChanged = new EventEmitter<TableSortOptions<PromptSortByOption>>();
  @Output() markAsSecretClicked = new EventEmitter<PromptResponse>();
  @Output() unmarkAsSecretClicked = new EventEmitter<PromptResponse>();

  workspaceRole = WorkspaceRole;
  promptSortByOption = PromptSortByOption;
  tablePropertyToSortOptionMap: Record<string, PromptSortByOption> = {
    promptName: PromptSortByOption.PromptName,
    lastModifiedAt: PromptSortByOption.LastModifiedAt,
  };
  isFullScreenSpinnerLoading = false;
  readonly PromptTableAction = PromptTableAction;

  constructor(private router: Router, private activatedRoute: ActivatedRoute) {}

  go(prompt: PromptResponse) {
    this.promptRowClicked.emit(prompt);
  }

  openToogleApiDialog(e: Event, prompt: PromptResponse) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    this.toogleApiClicked.emit(prompt);
  }

  sort(property: string) {
    const sortOptions = {
      sortAscending: this.sortOptions ? !this.sortOptions?.sortAscending : false,
      sortBy: this.tablePropertyToSortOptionMap[property],
    };
    this.sortOptionsChanged.emit(sortOptions);
  }

  editClick(e: Event, prompt: PromptResponse) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    this.editClicked.emit(prompt);
  }

  archiveClick(e: Event, prompt: PromptResponse) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    this.archiveClicked.emit(prompt);
  }

  activateClick(e: Event, prompt: PromptResponse) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    this.activateClicked.emit(prompt);
  }

  copyToWorkspaceClick(e: Event, prompt: PromptResponse) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    this.copyToWorkspaceClicked.emit(prompt);
  }

  markAsSecret(e: Event, prompt: PromptResponse) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    this.markAsSecretClicked.emit(prompt);
  }

  unmarkAsSecret(e: Event, prompt: PromptResponse) {
    e.stopPropagation();
    e.stopImmediatePropagation();
    this.unmarkAsSecretClicked.emit(prompt);
  }
}
