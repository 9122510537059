import { Pipe, PipeTransform } from '@angular/core';
import { UserRoleEnum, UserRoleNameMap } from '../consts/user-role.enum';

@Pipe({
  name: 'getUserRole',
})
export class GetUserRolePipe implements PipeTransform {
  transform(role: UserRoleEnum): string {
    return UserRoleNameMap[role];
  }
}
