import { Directive, ElementRef, Input, OnChanges, Renderer2, SimpleChanges } from '@angular/core';
import { AuthenticationService } from '@core/services';
import { WorkspaceRole } from '@core/models/response';

@Directive({
  selector: '[appRequiredRoleIds]',
})
export class RequiredRolesDirective implements OnChanges {
  @Input() appRequiredRoleIds: number[] = [];

  constructor(private authService: AuthenticationService, private elRef: ElementRef, private renderer: Renderer2) {
    this.validateAccess();
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.validateAccess();
  }

  private validateAccess(): void {
    if (!this.appRequiredRoleIds?.length) {
      return;
    }
    const currentUser = this.authService.userSubject.getValue();

    if (!this.appRequiredRoleIds.find((r: any) => r === currentUser.organizationRoleId)) {
      this.renderer.setStyle(this.elRef.nativeElement, 'display', 'none');
    }
  }
}
