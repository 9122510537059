import { OpenAiModels } from '../shared.model';
import { AiSystemConfigs, AISystemName } from '@core/models/app-config.model';

export class PromptResponse {
  promptVersionId?: string;
  promptId: string;
  versionId?: string;
  defaultVersionId?: string;
  isActive?: boolean;
  isApiEnabled?: boolean;
  promptName?: string;
  promptDescription?: string;
  promptTags?: string;
  isSecret?: boolean;
  aiSystem?: string;
  model?: string;
  promptText?: string;
  replacements?: Record<string, any>;
  systemMessage?: string;
  metadata?: Record<string, any>;
  configuration?: Record<string, any>;
  stream?: boolean;
  conversationId?: string;
  userMiniResponse?: UserMiniResponse;
  lastCreatedAt?: string;
  lastModifiedAt?: string;
}
export class UserMiniResponse {
  userId?: string;
  firstName?: string;
  lastName?: string;
  email?: string;
  profileImageUrl?: string;
}
export class PromptDetailResponse {
  promptId: string;
  promptName: string;
  promptDescription: string;
  isActive: boolean;
  isApiEnabled: boolean;
  isSecret: boolean;
  defaultVersionId: string;
  aiSystem: string;
  model: string;
  ownedBy: string;
  promptVersions: PromptVersionDetailResponse[];
  public GetOpenAiPromptType(): OpenAiPromptType {
    if (
      this.model == OpenAiModels.TextDavinci003 ||
      this.model == OpenAiModels.TextCurie001 ||
      this.model == OpenAiModels.TextAda001 ||
      this.model == OpenAiModels.TextBabbage001
    )
      return OpenAiPromptType.Text;
    else if (this.model == OpenAiModels.Gpt35Turbo || this.model == OpenAiModels.Gpt35Turbo16k || this.model == OpenAiModels.Gpt4)
      return OpenAiPromptType.Chat;
    else if (this.model == OpenAiModels.TextDavinciEdit001) return OpenAiPromptType.Edit;
    return OpenAiPromptType.None;
  }
}

export class PromptVersionDetailResponse {
  promptVersionId: string;
  promptId: string;
  promptText: string;
  configuration: AiSystemConfigs;
  metadata: Record<string, any>;
  isActive: boolean;
  aiSystem: AISystemNameResponse;
}

export enum AISystemNameResponse {
  OpenAi = 'OpenAi',
  AzureOpenAi = 'AzureOpenAi',
  AnthropicClaude = 'Anthropic',
  Cohere = 'Cohere', 
  GooglePalm = 'GooglePalm',
  GoogleGemini = 'GoogleGemini', 
  Groq = 'Groq', 
}

export const AISystemNameResponseToAiSystemMap = {
  [AISystemNameResponse.OpenAi]: AISystemName.OpenAi,
  [AISystemNameResponse.AzureOpenAi]: AISystemName.AzureOpenAi,
  [AISystemNameResponse.AnthropicClaude]: AISystemName.Anthropic,
  [AISystemNameResponse.GooglePalm]: AISystemName.GooglePalm,
  [AISystemNameResponse.GoogleGemini]: AISystemName.GoogleGemini,
  [AISystemNameResponse.Groq]: AISystemName.Groq,
  [AISystemNameResponse.Cohere]: AISystemName.Cohere,
};

export const AISystemNameToAiSystemResponseMap = {
  [AISystemName.OpenAi]: AISystemNameResponse.OpenAi,
  [AISystemName.AzureOpenAi]: AISystemNameResponse.AzureOpenAi,
  [AISystemName.Anthropic]: AISystemNameResponse.AnthropicClaude,
  [AISystemName.GooglePalm]: AISystemNameResponse.GooglePalm,
  [AISystemName.GoogleGemini]: AISystemNameResponse.GoogleGemini,
  [AISystemName.Groq]: AISystemNameResponse.Groq,
  [AISystemName.Cohere]: AISystemNameResponse.Cohere,
};

enum OpenAiPromptType {
  Text,
  Chat,
  Edit,
  None,
}

export class PromptVersionResponse {
  promptVersionId: string;
  promptId: string;
  promptName: string;
  promptText: string;
  isActive: boolean;
  metadata: Record<string, any>;
  configuration: Record<string, any>;
  public constructor(init?: Partial<PromptVersionResponse>) {
    Object.assign(this, init);
  }
}

export class PromptCounts {
  activePromptCount: number;
  archivedPromptCount: number;
}

export enum PromptSortByOption {
  PromptName = 'PromptName',
  Model = 'Model',
  AiSystem = 'AiSystem',
  LastModifiedAt = 'LastModifiedAt',
  CreatedAt = 'CreatedAt',
}
